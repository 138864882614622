import React, { useEffect, useState } from 'react';

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown, useLang } from '@manulife/mux';
import { getAmountNumbers,addCurrencySign } from '../../../services/helpers/commonHelpers';
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";

function RtgSynChildCriticalIllnessAmount({ chIndex }) {
    const { num5000, num50000, numD125 } = NumberConstants;
    const [options, setOptions] = useState([]);
    const selectedLang = useLang();
    const { control, getValues, watch, setValue } = useFormContext(); // retrieve all hook methods

    const amount = watch("rtgAmount");

    useEffect(() => {
        setValue(`rtgSynChilds.${chIndex}.criticalIllnessAmount`, "");
    }, [amount, setValue, chIndex]);

    useEffect(() => {
        const baseCoverageAmount = Number(getAmountNumbers(getValues("rtgAmount"), selectedLang));
        const maxVal = num50000;
        const minVal = num5000;
        const count = Math.floor((numD125 * baseCoverageAmount) / minVal);
        let optionList = [];
        for (let i = 1; i <= count; i++) {
            const val = minVal * i;
            const option = {
                label: addCurrencySign(val, selectedLang),
                value: String(val),
            };
            if (option.value <= maxVal) {
                optionList.push(option);
            } else {
                break;
            };
        }
        setOptions(optionList);
    }, [selectedLang, getValues, amount, num5000, num50000, numD125]);

    return (
        <>
            <Controller
                control={control}
                name={`rtgSynChilds.${chIndex}.criticalIllnessAmount`}
                rules={{
                    required: Labels.errors.required[`${selectedLang}`]
                }}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                }) => (
                    <Dropdown
                        id={name}
                        dropdownItems={options}
                        placeholder={Labels.select[`${selectedLang}`].label}
                        label={Labels.criticalIllnessAmount[`${selectedLang}`].label}
                        ariaLabel={`${Labels.child[`${selectedLang}`]} ${chIndex + 1} ${Labels.criticalIllnessAmount[`${selectedLang}`].label}`}
                        ariaRequired={true}
                        customStyle={{
                            rootStyle: {
                                width: "100%",
                            },
                        }}
                        ref={ref}
                        value={value}
                        onBlur={onBlur}
                        onChange={(newValue) => {
                            onChange(newValue);
                        }}
                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                    />
                )}
            />
        </>
    )
};

export default RtgSynChildCriticalIllnessAmount;