import { Button, Card, Progress, Stack, TextLink, colors, rem, useLang } from "@manulife/mux";
import { Download, Email } from "@manulife/mux-cds-icons";
import { useDispatch, useSelector } from "react-redux";
import { downloadPDF } from "../../services";
import { ChartIndexLabel, GraphLine, NormalText, NormalText14, NormalTextW400 } from "../../assets/appStyledComponents";
import ResultLables from '../../assets/resultLabels.json';
import NewPurchaseTabText from '../../assets/newPurchaseTabText.json';
import { useEffect, useState } from "react";
import AssumptionsDisclaimersModal from "./AssumptionsDisclaimersNewPurchase";
import UnderwritingRiskModal from "./UnderwritingRiskModal";
import { formatAmount } from "../../services/helpers/commonHelpers";
import LineChart from "./lineChart";
import { emailFtChart } from "../../services/helpers/emailTemplate";
import { fetchCharts } from "../../appRedux/chart/Actions";
import { isObjectEmpty } from "../../services/helpers/objectUtilities";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

function NewPurchaseTab() {

  const {num56, num16, num20, num2} = NumberConstants;

  const selectedLang = useLang();
  const dispatch = useDispatch();

  const validatedData = useSelector(
    (state) => state.rportReducer?.validatedData
  );

  const reportPdfBase64str = useSelector(
    (state) => state.chartReducer?.chartsData?.illustration?.report?.data || ""
  );

  const chartData = useSelector(
    (state) => state.chartReducer?.chartsData?.illustration?.report?.graph.find(ele => ele.type === "PremiumCompareNewPolicy")
  );

  const isLoading = useSelector(
    (state) => state.chartReducer?.isLoading
  );

  const [isOpening, setIsOpening] = useState(true);

  useEffect(() => {
    if (reportPdfBase64str === "" && !isObjectEmpty(validatedData) && dispatch && isOpening) {
      setIsOpening(false);
      dispatch(
        fetchCharts({
          ...validatedData,
          isDownloadPDF: true,
          reportType: "PremiumCompare"
        })
      );
    }
  }, [reportPdfBase64str, dispatch, validatedData, isOpening]);

  const [isUnderwritingRiskModalOpen, setUnderwritingRiskModalOpen] = useState(false);

  function openUnderwritingRiskModal() {
    setUnderwritingRiskModalOpen(true);
  };

  function closeUnderwritingRiskModal() {
    setUnderwritingRiskModalOpen(false);
  };

  const [isAssumptionsDisclaimersModalOpen, setAssumptionsDisclaimersModalOpen] = useState(false);

  function openAssumptionsDisclaimersModal() {
    setAssumptionsDisclaimersModalOpen(true);
  };

  function closeAssumptionsDisclaimersModal() {
    setAssumptionsDisclaimersModalOpen(false);
  };

  return chartData ? (
    <>
      <Card.Content>
        <NormalText>{NewPurchaseTabText.line1[`${selectedLang}`]}</NormalText>
        <NormalText14>{NewPurchaseTabText.line2[`${selectedLang}`]}</NormalText14>
        {chartData && <LineChart chartData={chartData} validatedData={validatedData} selectedLang={selectedLang} />}
      </Card.Content>
      <Card.Separator />
      <Card.Content>
        <ChartIndexLabel>{ResultLables.totalPremium65[`${selectedLang}`].label}</ChartIndexLabel>
        {<Stack justify="space-around" style={{ flexGrow: 1, paddingBottom: rem(num20) }}>
          <Stack direction="row" align="center" gapX={rem(num56)} style={{ width: '100%' }}>
            <Stack direction="row" align="center" gapX={rem(num16)} >
              <GraphLine style={{ background: "#00C46D" }} />
              <NormalTextW400 style={{ margin: 0 }}>T10</NormalTextW400>
            </Stack>
            <NormalTextW400>{formatAmount(chartData?.scalar.find(ele => ele.coverageProduct === "Term10").value?.toFixed(num2) || 0, selectedLang)}</NormalTextW400>
          </Stack>
          <Stack direction="row" align="center" gapX={rem(num56)} style={{ width: '100%' }}>
            <Stack direction="row" align="center" gapX={rem(num16)} >
              <GraphLine style={{ background: "#FE9400" }} />
              <NormalTextW400 style={{ margin: 0 }}>T20</NormalTextW400>
            </Stack>
            <NormalTextW400>{formatAmount(chartData?.scalar.find(ele => ele.coverageProduct === "Term20").value?.toFixed(num2) || 0, selectedLang)}</NormalTextW400>
          </Stack>
          <Stack direction="row" align="center" gapX={rem(num56)} style={{ width: '100%' }}>
            <Stack direction="row" align="center" gapX={rem(num16)} >
              <GraphLine style={{ background: "#0273B5" }} />
              <NormalTextW400 style={{ margin: 0 }}>T65</NormalTextW400>
            </Stack>
            <NormalTextW400>{formatAmount(chartData?.scalar.find(ele => ele.coverageProduct === "Term65").value?.toFixed(num2) || 0, selectedLang)}</NormalTextW400>
          </Stack>
        </Stack>}
        <Stack direction="row">
          <TextLink to="" aria-label={ResultLables.underwritingRisk[`${selectedLang}`].label} onClick={(e) => {
            e.preventDefault();
            openUnderwritingRiskModal();
          }}>{ResultLables.underwritingRisk[`${selectedLang}`].label}</TextLink>
          <TextLink to="" aria-label={ResultLables.assumptionsAndDisclaimers[`${selectedLang}`].label} onClick={(e) => {
            e.preventDefault()
            openAssumptionsDisclaimersModal();
          }}>{ResultLables.assumptionsAndDisclaimers[`${selectedLang}`].label}</TextLink>
        </Stack>
      </Card.Content>
      <Card.Separator />
      <Card.Content>
        <Stack direction="row">
          <Button
            variant={Button.VARIANT.TERTIARY_NOICON}
            icon={<Download color={colors.m_green} />}
            disabled={isLoading}
            saving={isLoading}
            onClick={() => downloadPDF(reportPdfBase64str)}
          >
            {ResultLables.downloadPDF[`${selectedLang}`].label}
          </Button>
          <Button
            variant={Button.VARIANT.TERTIARY_NOICON}
            icon={<Email color={colors.m_green} />}
            onClick={async () => await emailFtChart({
              ...validatedData,
              isDownloadPDF: true,
              reportType: "PremiumCompare"
            }, selectedLang)}
          >
            {ResultLables.email[`${selectedLang}`].label}
          </Button>
        </Stack>
      </Card.Content>
      <Card.Separator />
      <Card.Content>
        <NormalText>{ResultLables.tabBottomLine[`${selectedLang}`].label}</NormalText>
      </Card.Content>
      <UnderwritingRiskModal isModalOpen={isUnderwritingRiskModalOpen} closeModal={closeUnderwritingRiskModal} />
      <AssumptionsDisclaimersModal isModalOpen={isAssumptionsDisclaimersModalOpen} closeModal={closeAssumptionsDisclaimersModal} />
    </>
  ) : (
    <Card.Content>
      <Stack justify="center" style={{ minHeight: '420px' }}>
        <Stack direction="row" justify="center" >
          <Progress isFloating={false} />
        </Stack>
      </Stack>
    </Card.Content>
  )
};

export default NewPurchaseTab;
