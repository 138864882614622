import { Stack, colors, rem, useLang } from '@manulife/mux';
import { Conversation, Login2 } from '@manulife/mux-cds-icons';
import Labels from "../../assets/labels";
import { FooterBtnLabel } from '../../assets/appStyledComponents';
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

export default function RtgPageFooter() {
    const {num40, num20} = NumberConstants;
    const selectedLang = useLang();
    const styles = {
        outline: {
            backgroundColor: colors.m_green,
            width: '100%',
            alignItems: "stretch",
            justifyContent: 'center'
        },
        placeholder: {
            border: `solid 1px ${colors.light_4_dark_navy}`,
            backgroundColor: colors.m_green,
            padding: rem(num40),
            width: "49%",
            flexGrow: 1,
            justifyContent: `center`,
            textAlign: "center",
            cursor: "pointer"
        },
    };

    const onHover = (e) => {
        e.currentTarget.style.backgroundColor = colors.dark_1_green;
    };
    const offHover = (e) => {
        e.currentTarget.style.backgroundColor = colors.m_green;
    };

    return (
        <Stack direction="row" gap="0" style={{ ...styles.outline }}>
            <Stack
                gap={rem(num20)}
                // style={windowWidth >= BREAKPOINTS.DESKTOP_SM ? styles.placeholder : { ...styles.placeholder, width: `100%` }}
                data-testid={`footerBtnContact`}
                style={styles.placeholder}
                onMouseOver={onHover}
                onMouseOut={offHover}
                onClick={() => window.open(Labels.contactUs[selectedLang].url)}
                onKeyDown={event => {
                    if (event.key === 'Enter' || event.key ===' ') {
                        window.open(Labels.contactUs[selectedLang].url)
                    }
                }}
                tabIndex={0}
            >
                <Conversation color={colors.white} fontSize={rem(num40)} />
                <FooterBtnLabel>{Labels.contactUs[selectedLang].label}</FooterBtnLabel>
            </Stack>
            <Stack
                gap={rem(num20)}
                // style={windowWidth >= BREAKPOINTS.DESKTOP_SM ? styles.placeholder : { ...styles.placeholder, width: `100%` }}
                style={styles.placeholder}
                onMouseOver={onHover}
                onMouseOut={offHover}
                onClick={() => window.open(Labels.contactYourWholesaler[selectedLang].url)}
                onKeyDown={event => {
                    if (event.key === 'Enter' || event.key ===' ') {
                        window.open(Labels.contactYourWholesaler[selectedLang].url)
                    }
                }}
                tabIndex={0}
            >
                <Login2 color={colors.white} fontSize={rem(num40)} />
                <FooterBtnLabel>{Labels.contactYourWholesaler[selectedLang].label}</FooterBtnLabel>
            </Stack>
        </Stack>
    );
};
