import {
  DOWNLOAD_REPORT_REQUEST_END,
  DOWNLOAD_REPORT_REQUEST_START,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
} from "./Types";

const initialState = {
  isLoading: false,
  isDownloading: false,
  data: {},
  validatedData: {},
  error: "",
};

const ReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DOWNLOAD_REPORT_REQUEST_START:
      return {
        ...state,
        isDownloading: true,
      };

    case DOWNLOAD_REPORT_REQUEST_END:
      return {
        ...state,
        isDownloading: false,
      };

    case FETCH_REPORT_SUCCESS:
      const validatedData = payload.validatedData;
      delete payload.validatedData;
      return {
        isLoading: false,
        data: payload,
        validatedData: validatedData,
        error: "",
      };

    case FETCH_REPORT_FAILURE:
      return {
        isLoading: false,
        data: {},
        validatedData: {},
        error: payload,
      };

    default:
      return state;
  }
};

export default ReportReducer;
