import { BREAKPOINTS, Button, Modal, Stack, colors, rem, useLang } from "@manulife/mux";
import { Cross } from '@manulife/mux-cds-icons';
import {ModalHeader, NormalText, NormalText14 } from "../../assets/appStyledComponents/styledParagraphComponents";
import useWindowSize from "../../customHooks/useWindowSize";
import TEXT from "../../assets/UnderwritingRiskText.json";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

function UnderwritingRiskModal({ isModalOpen, closeModal }) {

    const {num960, num40, num20, num60} = NumberConstants;
    const selectedLang = useLang();

    const { width: windowWidth } = useWindowSize();

    let modalStyle = {
        width: rem(num960),
        padding: 0
    };

    if (windowWidth <= BREAKPOINTS.DESKTOP_SM) {
        modalStyle = {
            ...modalStyle,
            width: "95vw",
            maxHeight: "95vh"
        }
    };

    return (
        <Modal isOpen={isModalOpen} showCloseButton={false} shouldCloseOnBackdropClick={false} customStyle={{ modalStyle }}>
            <Stack direction="row" align="center" justify="flex-end" >
                <Button
                    variant={Button.VARIANT.TERTIARY_BACK}
                    icon={<Cross fontSize={rem(num20)} color={colors.super_dark_navy} style={{ marginRight: 0 }} />}
                    customStyle={{
                        buttonStyle: {
                            padding: 0,
                            width: rem(num40),
                            height: rem(num40)
                        }
                    }}
                    ariaLabel="closeModal"
                    onClick={closeModal}
                ></Button>
            </Stack>
            <Stack align="flex-start" gap={rem(num40)} style={{ padding: `${rem(0)} ${rem(num40)} ${rem(num60)}` }}>
                <ModalHeader data-testid="UnderwritingRiskModalHeader">{TEXT.modalHeader[`${selectedLang}`]}</ModalHeader>
                <Stack align="flex-start" gap={rem(num20)}>
                    <NormalText>{TEXT.modalContent1[`${selectedLang}`]}</NormalText>
                    <NormalText14>{TEXT.modalContent2[`${selectedLang}`]}<i>{TEXT.iVitality[`${selectedLang}`]}</i>{TEXT.modalContent3[`${selectedLang}`]}</NormalText14>
                </Stack>
            </Stack>
        </Modal>
    )
};

export default UnderwritingRiskModal;