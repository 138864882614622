import {
  DOWNLOAD_REPORT_REQUEST_END,
  DOWNLOAD_REPORT_REQUEST_START,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
} from "./Types";
import { callApi } from "../../services";
import constants from "../../assets/constants.json";
import { eProduct, genrateUDM } from "../../services/helpers/udmGenerator";

export const fetchReportRequest = () => {
  return {
    type: FETCH_REPORT_REQUEST,
  };
};

export const downloadReportRequestStart = () => {
  return {
    type: DOWNLOAD_REPORT_REQUEST_START,
  };
};

export const downloadReportRequestEnd = () => {
  return {
    type: DOWNLOAD_REPORT_REQUEST_END,
  };
};

export const fetchReportSuccess = (ReportData, validatedData = {}) => {
  let data = ReportData;
  if (validatedData && !data?.isDownloadPDF) {
    data = { ...ReportData, validatedData };
  }
  return {
    type: FETCH_REPORT_SUCCESS,
    payload: data,
  };
};

export const fetchReportFailure = (error) => {
  return {
    type: FETCH_REPORT_FAILURE,
    payload: error,
  };
};

export const fetchReport = (data) => {
  const UDM = genrateUDM(data)
  const endpoint = data.product === eProduct.GI
    ? `/apix/gicalculate`
    : `/apix/calculate`;
  return async (dispatch) => {
    if (!data.isDownloadPDF) {
      dispatch(fetchReportRequest());
    } else {
      dispatch(downloadReportRequestStart());
    }
    callApi(
      {
        httpMethod: constants.POST,
        apiEndPoint: endpoint,
        body: UDM,
      },
      ({ response, status, errorMsg }) => {
        if (status) {
          dispatch(fetchReportSuccess(response, data));
        } else {
          dispatch(fetchReportFailure(errorMsg));
        }
        dispatch(downloadReportRequestEnd());
      },
      dispatch
    );
  };
};

