import React, { useEffect, useState } from "react";
import {
  MuxProvider,
  LANG,
  Layout,
  rem,
  Stack,
  BREAKPOINTS,
} from "@manulife/mux";
import { Manulife, ManulifeFr } from "@manulife/mux-logos";
import "./App.css";
import DocumentTitle from 'react-document-title';
import RtgPageHeader from "./components/RtgPageHeader/RtgPageHeader";
import RtgPageDisclaimer from "./components/RtgPageDisclaimer/RtgPageDisclaimer";
import RtgAppHeader from "./components/RtgAppHeader/RtgAppHeader";
import RtgFooter from "./components/RtgFooter/RtgFooter";
import RtgPageFooter from "./components/RtgPageFooter/RtgPageFooter";
import { Provider } from "react-redux";
import RtgStore from "./appRedux/RtgStore";
import RtgSideNav from "./components/RtgSideNav/RtgSideNav";
import RtgPageBody from "./components/RtgPageBody/RtgPageBody";
import useWindowSize from "./customHooks/useWindowSize";
import CONSTANTS from "./assets/constants.json";
import NumberConstants from "../src/assets/appStyledComponents/numberConstant.json";

const App = () => {
  const { num30 } = NumberConstants;
  const { width: windowWidth } = useWindowSize();
  const domain = window.location.href;
  // const domain = CONSTANTS.domainString;
  const [selectedLang, setSelectedLang] = useState({ lang: LANG.FR_CA });
  const styles = {
    logo: {
      height: rem(num30),
      margin: `${rem(num30)} ${rem("0")}`,
    },
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    return () => true;
  }, []);

  useEffect(() => {
    if (domain && domain.includes(CONSTANTS.domainString)) {
      setSelectedLang({ lang: LANG.EN_CA });
    } else {
      setSelectedLang({ lang: LANG.FR_CA });
    }
    return () => true;
  }, [domain]);
  const TITLE = selectedLang.lang === LANG.EN_CA ? 'Manulife Rates To Go' : 'Manuvie Taux Express';

  return (
    <DocumentTitle title={TITLE}>
      <Provider store={RtgStore}>
        <MuxProvider langProvider={selectedLang}>
          <Layout
            header={<RtgAppHeader setSelectedLang={setSelectedLang} />}
            footer={<RtgFooter />}
            sideNav={<RtgSideNav setSelectedLang={setSelectedLang} />}
          >
            {windowWidth > BREAKPOINTS.MAX_1024 && (
              <Stack
                align="flex-start"
                style={{
                  padding: `${rem(0)} ${rem(num30)}`,
                }}
              >
                <Stack
                  direction="row"
                  justify="flex-start"
                  style={{ width: "100%" }}
                >
                  <>
                    {selectedLang.lang === LANG.EN_CA ? (
                      <Manulife.WORDMARK_BLACK
                        title="Manulife"
                        style={styles.logo}
                      />
                    ) : (
                      <ManulifeFr.WORDMARK_BLACK
                        title="Manuvie"
                        style={styles.logo}
                      />
                    )}
                  </>
                </Stack>
              </Stack>
            )}
            <Stack
              style={{
                margin:
                  windowWidth > BREAKPOINTS.MAX_1024
                    ? `${rem(0)} ${rem(num30)} ${rem(num30)}`
                    : `${rem(num30)}`,
              }}
            >
              <Stack align="flex-start" gap="0.5rem">
                <Stack justify="center">
                  <Stack
                    align="flex-start"
                    gap="0.5rem"
                    style={{
                      flexGrow: 1,
                      width:
                        windowWidth < BREAKPOINTS.DESKTOP_LG
                          ? "auto"
                          : rem(BREAKPOINTS.DESKTOP_MED),
                    }}
                  >
                    <RtgPageHeader />
                    <RtgPageBody />
                    <RtgPageDisclaimer />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <RtgPageFooter />
          </Layout>
        </MuxProvider>
      </Provider>
    </DocumentTitle>
  );
};

export default App;
