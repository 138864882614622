import { LANG, P, H2 } from "@manulife/mux";
const ageModel = ({ lang }) => {
  return lang === LANG.EN_CA ? (
    <>
      <H2>Age</H2>
      <P>
        Insurance age is considered “age nearest to”. In other words, we
        determine a person’s insurance age using the birthday nearest to the
        coverage date. Based on the coverage date:
      </P>
      <ul>
        <li>
          If the person’s birthday was less than half a year ago, we use their
          current age.
        </li>
        <li>
          If the person’s birthday was more than half a year ago, we use the
          age they will be on their next birthday.
        </li>
      </ul>
      <P>Example: Today is July 1, and the person turned:</P>
      <ul>
        <li>40 years old on April 2, we use age 40 as the insurance age.</li>
        <li>
          40 years old on December 2, we use age 41 as the insurance age.
        </li>
      </ul>
    </>
  ) : (
    <>
      <H2>Âge</H2>
      <P>
        L’âge tarifé est « l’âge le plus proche ». Autrement dit, nous
        déterminons l’âge tarifé d’une personne en utilisant son anniversaire de
        naissance le plus proche de la date de la couverture. Selon la date de
        couverture :
      </P>
      <ul>
        <li>
          Si le dernier anniversaire de naissance de la personne a eu lieu au
          cours des six derniers mois, l’âge tarifé correspond à l’âge actuel.
        </li>
        <li>
          Si l’anniversaire de naissance de la personne remonte à plus de six
          mois, nous utilisons l’âge qu’elle aura à son prochain anniversaire.
        </li>
      </ul>
      <P>
        Exemple : Aujourd’hui, nous sommes le 1er juillet et la personne a eu :
      </P>
      <ul>
        <li>40 ans le 2 avril, 40 ans est l’âge tarifé.</li>
        <li>40 ans le 2 décembre, 41 ans est l’âge tarifé.</li>
      </ul>
    </>
  );
};
export default ageModel;
