import React, { useState } from "react";

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from "react-hook-form";
import { TextInput, useLang, Modal, TextLink } from "@manulife/mux";
import {
  getAmountNumbers,
  getMinMaxAgeLimit,
  getOnlyNumbers,
  replaceKeysWithValues,
} from "../../../services/helpers/commonHelpers";
import { ContentWrapper } from "../../../assets/appStyledComponents";
import AgeModelText from "../../../assets/ageModel";
import { eProduct } from "../../../services/helpers/udmGenerator";

function RtgAge() {
  const selectedLang = useLang();
  const { control, getValues } = useFormContext(); // retrieve all hook methods
  const [isModalOpen, setModalOpen] = useState(false);

  function openModal(e) {
    e.preventDefault();
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  const getAgeLabel = () => {
    const rtgCostType = getValues("rtgCostType");
    const rtgSelectedProduct = getValues("rtgSelectedProduct");
    let ageLabel = `${Labels.age[`${selectedLang}`].label} `;
    if ((!!rtgCostType && rtgSelectedProduct !== "") || rtgSelectedProduct === eProduct.GI) {
      const { min, max } = getMinMaxAgeLimit(rtgCostType, rtgSelectedProduct);
      ageLabel = `${Labels.age[`${selectedLang}`].label} ${replaceKeysWithValues(
        Labels.ageSubText[`${selectedLang}`],
        {
          min_age: min,
          max_age: max,
        }
      )} `;
    }
    return (
      <>
        {ageLabel}
        <TextLink to="" onClick={openModal} aria-label={Labels.ariaAgeLearnMore[`${selectedLang}`]}>
          {Labels.LearnMore[`${selectedLang}`]}
        </TextLink>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <ContentWrapper>
            <AgeModelText lang={selectedLang} />
          </ContentWrapper>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Controller
        control={control}
        name="rtgAge"
        rules={{
          required: Labels.errors.required[`${selectedLang}`],
          validate: (value, { rtgCostType, rtgSelectedProduct }) => {
            const { min, max } = getMinMaxAgeLimit(
              rtgCostType,
              rtgSelectedProduct
            );
            if (
              Number(getAmountNumbers(value)) < min ||
              Number(getAmountNumbers(value)) > max
            ) {
              return replaceKeysWithValues(
                Labels.errors.age[`${selectedLang}`],
                {
                  min_age: min,
                  max_age: max,
                }
              );
            }
            return null;
          },
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { error },
        }) => (
          <TextInput
            label={getAgeLabel()}
            ariaLabel={`${Labels.age[`${selectedLang}`].label}`}
            ariaRequired={true}
            data-testid={name}
            maxLength={2}
            customStyle={{
              rootStyle: {
                width: "100%",
              },
            }}
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={(newValue) => onChange(getOnlyNumbers(newValue))} //getOnlyNumbers
            errors={
              error && [
                { id: `error-${name}`, isError: true, msg: error.message },
              ]
            }
          />
        )}
      />
    </>
  );
}

export default RtgAge;
