import { ExpandablePanel, VIEWPORTS, colors, rem, typography, useLang } from '@manulife/mux';
import { ButtonPlusIcon, ButtonMinusIcon } from '@manulife/mux-cds-icons';
import Labels from "../../assets/labels";
import DisclaimerContent from './DisclaimerContent';
import useWindowSize from '../../customHooks/useWindowSize';
import { DisclaimerWrapper } from '../../assets/appStyledComponents';
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

export default function RtgPageDisclaimer() {
    const {num19, num2, num15, num16, num24} = NumberConstants;
    const selectedLang = useLang();
    const { width: windowWidth } = useWindowSize();

    return (<DisclaimerWrapper style={{
        borderTop: `solid ${rem(num2)} ${colors.light_grey}`,
        borderBottom: `solid ${rem(num2)} ${colors.light_grey}`,
        padding: `${rem(num19)} 0 ${rem(num19)} 0`,
        margin: (windowWidth < VIEWPORTS.XS) ? `0 ${rem(num15)} 0 ${rem(num15)}` : `0`
    }}>
        <ExpandablePanel
            data-testid="expandable-panel-disclaimer"
            customStyle={{
                titleStyle: {
                    margin: `$(rem(0)) $(rem(0)) $(rem(0)) $(rem(num8))`,
                    fontFamily: typography.fontFamily,
                    fontStyle: typography.fontStyle,
                    fontWeight: typography.fontWeightDemibold,
                    fontSize: rem(num16),
                    lineHeight: rem(num24),
        
                }
            }}
            variant={ExpandablePanel.VARIANT.SECONDARY}
            title={Labels.disclaimer[selectedLang]}
            expandIcon={< ButtonPlusIcon style={{'color':`${colors.coral}`, 'height':`1.625em`, 'fontSize': `1.25rem`}} viewBox="3 4 12 12" />}
            collapseIcon={< ButtonMinusIcon style={{'color':`${colors.coral}`, 'height':`1.625em`, 'fontSize': `1.25rem`}}  viewBox="3 4 12 12" />}
        >
            {DisclaimerContent(selectedLang)}
        </ExpandablePanel >
    </DisclaimerWrapper>
    )
};
