import React from "react";

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from "react-hook-form";
import { TextInput, useLang } from "@manulife/mux";
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";
import {
  getAmountNumbers,
  getOnlyNumbers,
  replaceKeysWithValues,
} from "../../../services/helpers/commonHelpers";

function RtgSynChildAge({ chIndex }) {
  const { num15, num17, num18 } = NumberConstants;
  const selectedLang = useLang();
  const { control } = useFormContext(); // retrieve all hook methods

  const getAgeLadel = () => {
    return `${Labels.age[`${selectedLang}`].label}`;
  };

  const getMinMaxChildAgeLimit = (rtgSynChilds) => {
    const CriticalIllness =
      rtgSynChilds[chIndex]?.insuredTypes?.CriticalIllness;
    if (CriticalIllness === true) {
      return { min: 0, max: num17 };
    }
    return { min: 0, max: num18 };
  };

  return (
    <>
      <Controller
        control={control}
        name={`rtgSynChilds.${chIndex}.age`}
        rules={{
          required: Labels.errors.required[`${selectedLang}`],
          validate: (value, { rtgSynChilds }) => {
            const { min, max } = getMinMaxChildAgeLimit(rtgSynChilds);
            if (
              Number(getAmountNumbers(value)) < min ||
              Number(getAmountNumbers(value)) > max
            ) {
              return replaceKeysWithValues(
                Labels.errors.childAge[`${selectedLang}`],
                {
                  min_age: num15,
                  max_age: max,
                }
              );
            };
            return null;
          },
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { error },
        }) => (
          <TextInput
            label={getAgeLadel()}
            ariaLabel={`${Labels.child[`${selectedLang}`]} ${chIndex + 1} ${
              Labels.age[`${selectedLang}`].label
            }`}
            ariaRequired={true}
            data-testid={name}
            maxLength={2}
            customStyle={{
              rootStyle: {
                width: "100%",
              },
            }}
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={(newValue) => onChange(getOnlyNumbers(newValue, true))} //getOnlyNumbers
            errors={
              error && [
                { id: `error-${name}`, isError: true, msg: error.message },
              ]
            }
          />
        )}
      />
    </>
  );
}

export default RtgSynChildAge;
