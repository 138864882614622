const tooltipText = {
  healthstyleTooltip: {
    "en-CA": {
      contentLine1:
        "Healthstyle 3 is used for a standard non-smoker risk and Healthstyle 5 is used if your client has used any tobacco/nicotine products in the past 12 months.",
    },
    "fr-CA": {
      contentLine1:
        "Indice-santé 3 est utilisé quand il s’agit d’un risque standard non-fumeur et Indice-santé 5 quand le client a consommé un produit du tabac ou un produit contenant de la nicotine au cours des 12 derniers mois.",
    },
  },
  arieHealthstyleTooltip: {
    "en-CA": "Healthstyle tooltip",
    "fr-CA": "Indice-santé infobulle"
  },
  costTypeTooltip: {
    "en-CA": {
      title:
      <>
        Indicates how long the premium for a new coverage stays the same. For Family Term with <i>Vitality Plus</i>™, it indicates how long the guaranteed maximum premium for a new coverage stays the same.
      </>
    },
    "fr-CA": {
      title:
      <>
        Indique la durée pendant laquelle la prime d’une nouvelle couverture demeure inchangée. Dans le cas d’un contrat Temporaire Famille avec <i>Vitalité Plus</i>ᴹᶜ, il indique la durée pendant laquelle la prime maximale garantie d’une nouvelle couverture demeure inchangée.
      </>
    },
  },
  ariaCostTypeTooltip: {
    "en-CA": "Cost type tooltip",
    "fr-CA": "Type de coût infobulle"
  },
};
export default tooltipText;
