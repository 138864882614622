import React, { useState } from "react";

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from "react-hook-form";
import {
  BREAKPOINTS,
  ButtonSelector,
  Modal,
  TextLink,
  Tooltip,
  useLang,
} from "@manulife/mux";
import Content from "../../../assets/tooltipText";
import { eProduct } from "../../../services/helpers/udmGenerator";
import { ContentWrapper } from "../../../assets/appStyledComponents";
import HealthStyleModelText from "../../../assets/healthStyleModel";
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";

function RtgHealthStyle() {
  const { num300, num200 } = NumberConstants;
  const selectedLang = useLang();
  const { control, getValues } = useFormContext(); // retrieve all hook methods
  const [isModalOpen, setModalOpen] = useState(false);
  function openModal(e) {
    e.preventDefault();
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }

  const getHealthStyleLabelValues = () => {
    const selectedProduct = getValues("rtgSelectedProduct");
    switch (selectedProduct) {
      case eProduct.FT:
      case eProduct.FTV:
        return {
          label: Labels.healthstyle[`${selectedLang}`].label,
          options: Labels.healthstyle[`${selectedLang}`].options,
          tooltip: (
            <Tooltip
              content={
                <>
                  {Content.healthstyleTooltip[`${selectedLang}`].contentLine1}
                </>
              }
              ariaLabel={Content.arieHealthstyleTooltip[`${selectedLang}`]}
              bubbleWidth={num200}
              variant={Tooltip.VARIANT.SECONDARY}
            />
          ),
        };
      case eProduct.LC:
      case eProduct.SYN:
      default:
        return {
          label: (
            <>
              {`${Labels.smokingStatus[`${selectedLang}`].label} `}
              <TextLink to=""
                onClick={openModal}
                aria-label={Labels.ariaHealthstyleLearnMore[`${selectedLang}`]}
              >
                {Labels.LearnMore[`${selectedLang}`]}
              </TextLink>
              <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ContentWrapper>
                  <HealthStyleModelText lang={selectedLang} />
                </ContentWrapper>
              </Modal>
            </>
          ),
          options: Labels.smokingStatus[`${selectedLang}`].options,
          tooltip: null,
        };
    }
  };

  return (
    <>
      <Controller
        control={control}
        name="rtgHealthStyle"
        rules={{
          required: Labels.errors.required[`${selectedLang}`],
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { error },
        }) => (
          <ButtonSelector
            id={name}
            label={getHealthStyleLabelValues().label}
            ariaLabel={`${Labels.smokingStatus[`${selectedLang}`].label}`}
            ariaRequired={true}
            values={getHealthStyleLabelValues().options}
            customStyle={{
              rootStyle: {
                width: "75%",
                media: [
                  {
                    maximum: BREAKPOINTS.MAX_1280,
                    width: "100%",
                  },
                ],
              },
            }}
            verticalStackingBreakPoint={num300}
            tooltip={getHealthStyleLabelValues().tooltip}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            errors={
              error && [
                { id: `error-${name}`, isError: true, msg: error.message },
              ]
            }
          />
        )}
      />
    </>
  );
}

export default RtgHealthStyle;
