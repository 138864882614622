import React from "react";

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from "react-hook-form";
import { Dropdown, Tooltip, useLang } from "@manulife/mux";
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";

function RtgSynChildCount() {
  const { num200 } = NumberConstants;
  const selectedLang = useLang();
  const { control } = useFormContext(); // retrieve all hook methods
  const getLabelValue = () => {
    return (
      <>
        {`${Labels.childCount[`${selectedLang}`].label} `}
        <Tooltip
          content={Labels.childCount[selectedLang].tooltipText}
          ariaLabel={Labels.ariaChildCount[selectedLang]}
          bubbleWidth={num200}
          variant={Tooltip.VARIANT.SECONDARY}
        />
      </>
    );
  };
  return (
    <>
      <Controller
        control={control}
        name="rtgSynChildCount"
        rules={{
          required: Labels.errors.required[`${selectedLang}`],
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { error },
        }) => (
          <Dropdown
            id={name}
            dropdownItems={Labels.childCount[`${selectedLang}`].options}
            placeholder={Labels.select[`${selectedLang}`].label}
            label={getLabelValue()}
            ariaLabel={`${Labels.childCount[`${selectedLang}`].label} `}
            ariaRequired={true}
            customStyle={{
              rootStyle: {
                width: "100%",
              },
            }}
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            errors={
              error && [
                { id: `error-${name}`, isError: true, msg: error.message },
              ]
            }
          />
        )}
      />
    </>
  );
}

export default RtgSynChildCount;
