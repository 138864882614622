import React from "react";

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from "react-hook-form";
import { Dropdown, useLang, Tooltip } from "@manulife/mux";
import { eCostType, eProduct } from "../../../services/helpers/udmGenerator";
import Content from "../../../assets/tooltipText";
import NumbarConstants from "../../../assets/appStyledComponents/numberConstant.json";

function RtgCostType() {
  const { num200 } = NumbarConstants;

  const selectedLang = useLang();
  const { control, getValues } = useFormContext(); // retrieve all hook methods

  const lifeChequeCT = [
    {
      label: "T10",
      value: eCostType.LC_T10,
    },
    {
      label: "T20",
      value: eCostType.LC_T20,
    },
    {
      label: "T65",
      value: eCostType.LC_T65,
    },
    {
      label: "T75",
      value: eCostType.LC_T75,
    },
    {
      label: "T100",
      value: eCostType.LC_T100,
    },
    {
      label: Labels.Pay[selectedLang],
      value: eCostType.LC_PAY15,
    },
  ];

  const synergyCT = [
    {
      label: "T10",
      value: eCostType.SYN_T10,
    },
    {
      label: Labels.Level[selectedLang],
      value: eCostType.SYN_LEVEL,
    },
  ];

  const familyTermCT = [
    {
      label: "T10",
      value: eCostType.FT_FTV_T10,
    },
    {
      label: "T20",
      value: eCostType.FT_FTV_T20,
    },
    {
      label: "T65",
      value: eCostType.FT_FTV_T65,
    },
  ];

  const renderCostType = (selectedProduct) => {
    switch (selectedProduct) {
      case eProduct.LC:
        return lifeChequeCT;
      case eProduct.SYN:
        return synergyCT;
      case eProduct.FT:
      case eProduct.FTV:
      default:
        return familyTermCT;
    }
  };
  return (
    <Controller
      control={control}
      name="rtgCostType"
      rules={{
        required: Labels.errors.required[`${selectedLang}`],
      }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => (
        <Dropdown
          id={name}
          label={
            <>
              {Labels.costType[`${selectedLang}`].label + " "}
              <Tooltip
                content={Content.costTypeTooltip[`${selectedLang}`].title}
                ariaLabel={Content.ariaCostTypeTooltip[`${selectedLang}`]}
                bubbleWidth={num200}
                variant={Tooltip.VARIANT.SECONDARY}
              />
            </>
          }
          ariaLabel={Labels.costType[`${selectedLang}`].label}
          ariaRequired={true}
          placeholder={Labels.select[`${selectedLang}`].label}
          dropdownItems={renderCostType(getValues("rtgSelectedProduct"))}
          customStyle={{
            rootStyle: {
              width: "100%",
            },
          }}
          ref={ref}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          errors={
            error && [
              { id: `error-${name}`, isError: true, msg: error.message },
            ]
          }
        />
      )}
    />
  );
}

export default RtgCostType;
