import React from 'react';

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxGroup, useLang } from '@manulife/mux';
import { isObjectEmpty } from '../../../services/helpers/objectUtilities';

import './style/RtgSynChildInsuredType.css';

function RtgSynChildInsuredType({ chIndex }) {
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods
    return (
        <>
            <Controller
                control={control}
                name={`rtgSynChilds.${chIndex}.insuredTypes`}
                rules={{
                    required: Labels.errors.childInsuranceType[`${selectedLang}`],
                    validate: (value) => {
                        if (!value?.CriticalIllness && !value?.LifeInsurance10k && isObjectEmpty(value)) {
                            return Labels.errors.childInsuranceType[`${selectedLang}`];
                        }
                        return null;
                    }
                }}
                render={({
                    field: { onChange, onBlur, value, name },
                    fieldState: { error },
                }) => (
                    <>
                        <CheckboxGroup
                            id={name}
                            values={Labels.insuredTypes[`${selectedLang}`].options}
                            ariaRequired={true}
                            selected={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                        />
                    </>
                )}
            />
        </>
    )
};

export default RtgSynChildInsuredType;