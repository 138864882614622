import {
  FETCH_CHARTS_FAILURE,
  FETCH_CHARTS_REQUEST,
  FETCH_CHARTS_SUCCESS,
} from "./Types";
import { callApi } from "../../services";
import constants from "../../assets/constants.json";
import { genrateUDM } from "../../services/helpers/udmGenerator";

export const fetchChartRequest = () => {
  return {
    type: FETCH_CHARTS_REQUEST,
  };
};

export const fetchChartSuccess = (chartsData) => {
  return {
    type: FETCH_CHARTS_SUCCESS,
    payload: chartsData,
  };
}

export const fetchChartFailure = (error) => {
  return {
    type: FETCH_CHARTS_FAILURE,
    payload: error,
  };
};

export const fetchCharts = (data) => {
  const UDM = genrateUDM(data);
  return async (dispatch, getState) => {
    const { chartReducer: { isLoading } } = getState();
    if (!isLoading) {
      dispatch(fetchChartRequest());
      callApi(
        {
          httpMethod: constants.POST,
          apiEndPoint: `/apix/getCharts`,
          body: UDM,
        },
        ({ response, status, errorMsg }) => {
          if (status) {
            dispatch(fetchChartSuccess(response));
          } else {
            dispatch(fetchChartFailure(errorMsg));
          }
        },
        dispatch
      );
    }
  };
};