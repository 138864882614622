import React from 'react';

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from 'react-hook-form';
import { BREAKPOINTS, ButtonSelector, useLang } from '@manulife/mux';
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";

function RtgSex({ fieldName }) {
    const { num300 } = NumberConstants;
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods
    return (
        <>
            <Controller
                control={control}
                name={fieldName}
                rules={{
                    required: Labels.errors.required[`${selectedLang}`]
                }}
                render={({
                    field: { onChange, onBlur, value, name },
                    fieldState: { error },
                }) => (
                    <ButtonSelector
                        id={name}
                        label={Labels.gender[`${selectedLang}`].label}
                        ariaLabel={Labels.gender[`${selectedLang}`].label}
                        ariaRequired={true}
                        values={Labels.gender[`${selectedLang}`].options}
                        customStyle={{
                            rootStyle: {
                                width: "75%",
                                media: [
                                    {
                                        maximum: BREAKPOINTS.MAX_1280,
                                        width: '100%',
                                    },
                                ],
                            },
                        }}
                        verticalStackingBreakPoint={num300}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                    />
                )}
            />
        </>
    );
};

export default RtgSex;