import React from "react";

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from "react-hook-form";
import { BREAKPOINTS, ButtonSelector, useLang } from "@manulife/mux";
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";

function RtgSynChildProtectionRiders() {
  const { num300 } = NumberConstants;
  const selectedLang = useLang();
  const { control } = useFormContext(); // retrieve all hook methods
  return (
    <>
      <Controller
        control={control}
        name="rtgSynChildProtectionRiders"
        rules={{
          required: Labels.errors.required[`${selectedLang}`],
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { error },
        }) => (
          <ButtonSelector
            id={name}
            label={Labels.childProtectionRiders[`${selectedLang}`].label}
            ariaLabel={Labels.childProtectionRiders[`${selectedLang}`].label}
            ariaRequired={true}
            values={Labels.childProtectionRiders[`${selectedLang}`].options}
            customStyle={{
              rootStyle: {
                width: "75%",
                media: [
                  {
                    maximum: BREAKPOINTS.MAX_1280,
                    width: "100%",
                  },
                ],
              },
            }}
            verticalStackingBreakPoint={num300}
            // ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            errors={
              error && [
                { id: `error-${name}`, isError: true, msg: error.message },
              ]
            }
          />
        )}
      />
    </>
  );
}

export default RtgSynChildProtectionRiders;
