const FtChartEmailTemplate = {
  subject: {
    "en-CA": "Your Rates to Go quote",
    "fr-CA": "Votre devis Taux Express",
  },
  bodyLine1: {
    "en-CA": "Initial premium as of {{chart_date}}",
    "fr-CA": "Prime initiale au {{chart_date}}",
  },
  footer: {
    "en-CA": "Insurance products are issued by The Manufacturers Life Insurance Company. Manulife's Rates to Go illustrates insurance premiums based on the information provided. This quote does not provide an offer to insure. Applications are subject to underwriting. Premium rates are subject to change without notice. © 2023 Manulife. All rights reserved.",
    "fr-CA": "Les produits d’assurance sont établis par La Compagnie d’Assurance-Vie Manufacturers. Les Taux Express de Manuvie indiquent les primes d’assurance en fonction des renseignements fournis. Ce devis ne constitue pas une proposition d’assurance. Les demandes sont assujetties à la tarification. Les taux de prime peuvent être modifiés sans préavis. © Manuvie, 2023. Tous droits réservés."
  }
};
export default FtChartEmailTemplate;
