import React, { useEffect } from 'react';
import RtgSynChildCount from './Fields/RtgSynChildCount';
import { useFieldArray, useFormContext } from 'react-hook-form';
import SynergyChildInputs from './SynergyChildInputs';

function SynergyChildDetails() {

    const { control, watch } = useFormContext(); // retrieve all hook methods
    const { fields, replace } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "rtgSynChilds", // unique name for your Field Array
    });

    const rtgSynChildCount = watch("rtgSynChildCount");

    useEffect(() => {
        const tempArray = [];
        for (let index = 0; index < Number(rtgSynChildCount); index++) {
            tempArray.push({})
        }
        replace(tempArray);
        return () => true;
    }, [rtgSynChildCount, replace]);


    return (
        <>
            <RtgSynChildCount />
            {fields.map((item, index) => (
                <SynergyChildInputs key={item.id} chIndex={index} />
            ))}
        </>
    )
};

export default SynergyChildDetails;