import { eProduct } from "./udmGenerator";
import FtChartEmailTemplate from "../../assets/ftChartEmailTemplete";
import EmailTemplate from "../../assets/emailContent";
import { emailGIBody, emailFtBody, emailFtChartBody, emailFtvBody, emailLcBody, emailSynBody } from "../../assets/emailBodies";

const getEmailBody = async (data, lang, validatedData) => {
  let { product: selectedProduct } = validatedData;
  switch (selectedProduct) {
    case eProduct.GI:
      return emailGIBody(data,lang, validatedData);
    case eProduct.FT:
      return emailFtBody(data,lang, validatedData);
    case eProduct.FTV:
      return emailFtvBody(data,lang, validatedData);
    case eProduct.LC:
      return emailLcBody(data,lang, validatedData);
    case eProduct.SYN:
      return await emailSynBody(data,lang, validatedData);
    default:
      return "";
  }
}

export const emailResults = async (data, lang, validatedData) => {
  const emailBody = await getEmailBody(data, lang, validatedData);
  window.location.href = `mailto: ?subject=${encodeURIComponent(EmailTemplate.subject[lang])}&body=${encodeURIComponent(emailBody)}`;
};

export const emailFtChart = async (data, lang) => {
  const emailBody = await emailFtChartBody(data, lang);
  window.location.href = `mailto: ?subject=${encodeURIComponent(FtChartEmailTemplate.subject[lang])}&body=${encodeURIComponent(emailBody)}`;
}