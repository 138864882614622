import {
  FETCH_CHARTS_FAILURE,
  FETCH_CHARTS_REQUEST,
  FETCH_CHARTS_SUCCESS,
} from "./Types";

const initialState = {
  isLoading: false,
  error: "",
  chartsData: {}
};

const ChartReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_CHARTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case FETCH_CHARTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chartsData: payload
      }

    default:
      return state;
  }
};

export default ChartReducer;
