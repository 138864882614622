import React from 'react';

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput, useLang } from '@manulife/mux';
import { NormalText } from '../../../assets/appStyledComponents';

function RtgSynChildName({ chIndex }) {
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods
    return (
        <>
            <Controller
                control={control}
                name={`rtgSynChilds.${chIndex}.name`}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                }) => (
                    <TextInput
                        label={<>
                            {Labels.childName[`${selectedLang}`].label} <NormalText style={{ display: "inline" }}>({Labels.optional[`${selectedLang}`].label})</NormalText>
                        </>}
                        ariaLabel={`${Labels.child[`${selectedLang}`]} ${chIndex + 1} ${Labels.childName[`${selectedLang}`].label}`}
                        data-testid={name}
                        customStyle={{
                            rootStyle: {
                                width: "100%",
                            },
                        }}
                        ref={ref}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                    />
                )}
            />
        </>
    )
};

export default RtgSynChildName;