import {
  eHealthStyle,
  eSex,
  eSmokingStatus,
} from "../services/helpers/udmGenerator";

const emailDisclaimerEN = "Insurance products are issued by The Manufacturers Life Insurance Company. Manulife's Rates to Go illustrates insurance premiums based on the information provided. This quote does not provide an offer to insure. Applications are subject to underwriting. Premium rates are subject to change without notice. © 2023 Manulife. All rights reserved.";

const emailDisclaimerFR = "Les produits d’assurance sont établis par La Compagnie d’Assurance-Vie Manufacturers. Les Taux Express de Manuvie indiquent les primes d’assurance en fonction des renseignements fournis. Ce devis ne constitue pas une proposition d’assurance. Les demandes sont assujetties à la tarification. Les taux de prime peuvent être modifiés sans préavis. © Manuvie, 2023. Tous droits réservés.";

const emailDisclaimerGIEN = "Insurance products are issued by The Manufacturers Life Insurance Company. Manulife's Rates to Go illustrates insurance premiums based on the information provided. This quote does not provide an offer to insure. Applications are subject to approval. Premium rates are subject to change without notice. © 2024 Manulife. All rights reserved.";

const emailDisclaimerGIFR = "Les produits d’assurance sont établis par La Compagnie d’Assurance-Vie Manufacturers. Taux Express de Manuvie illustre les primes d’assurance en fonction des renseignements fournis. Ce devis ne constitue pas une offre d’assurance. Les propositions sont assujetties à leur approbation. Les taux de prime peuvent être modifiés sans préavis. © Manuvie, 2024 Tous droits réservés.";

const EmailTemplate = {
  subject: {
    "en-CA": "Your Rates to Go quote",
    "fr-CA": "Votre devis Taux Express",
  },
  bodyLine1: {
    "en-CA": "Initial premium as of {{current_date}}",
    "fr-CA": "Prime initiale au {{current_date}}",
  },
  monthlyPremium: {
    "en-CA": "Monthly premium : {{monthly_premium}}",
    "fr-CA": "Prime mensuelle : {{monthly_premium}}",
  },
  annualPremium: {
    "en-CA": "Annual premium : {{annual_premium}}",
    "fr-CA": "Prime annuelle : {{annual_premium}}",
  },
  productWithCostTypeGI: {
    "en-CA": "Product : Manulife Guaranteed Issue Life",
    "fr-CA": "Produit : Vie Manuvie à émission garantie",
  },
  productWithCostTypeFT: {
    "en-CA": "Product : Family Term - {{costType}}",
    "fr-CA": "Produit : Temporaire Famille - {{costType}}",
  },
  productWithCostTypeFTV: {
    "en-CA": "Product : Family Term with 𝑉𝑖𝑡𝑎𝑙𝑖𝑡𝑦 𝑃𝑙𝑢𝑠™ - {{costType}}",
    "fr-CA": "Produit : Temporaire Famille avec 𝑉𝑖𝑡𝑎𝑙𝑖𝑡𝑒̀ 𝑃𝑙𝑢𝑠ᴹᶜ - {{costType}}",
  },
  productWithCostTypeLC: {
    "en-CA": "Product : Lifecheque - {{costType}}",
    "fr-CA": "Produit : Chèque-Vie - {{costType}}",
  },
  productWithCostTypeSYN: {
    "en-CA": "Product : Synergy - {{costType}}",
    "fr-CA": "Produit : Synergie - {{costType}}",
  },
  coverageAmount: {
    "en-CA": "Coverage amount : {{coverage_amount}}",
    "fr-CA": "Montant de la couverture : {{coverage_amount}}",
  },
  coverageAmountSYN: {
    "en-CA": "Synergy amount of insurance : {{coverage_amount}}",
    "fr-CA": "montant d’assurance Synergie : {{coverage_amount}}",
  },
  age: {
    "en-CA": "Age : {{age}}",
    "fr-CA": "Âge : {{age}}",
  },
  sex: {
    "en-CA": "Sex : {{sex}}",
    "fr-CA": "Sexe : {{sex}}",
  },
  healthstyle: {
    "en-CA": "Healthstyle : {{healthstyle}}",
    "fr-CA": "Indice-santé : {{healthstyle}}",
  },
  smokingStatus: {
    "en-CA": "Smoking status : {{smokingStatus}}",
    "fr-CA": "Usage du tabac : {{smokingStatus}}",
  },
  FTFooter: {
    "en-CA": emailDisclaimerEN,
    "fr-CA": emailDisclaimerFR
  },
  GIFooter: {
    "en-CA": emailDisclaimerGIEN,
    "fr-CA": emailDisclaimerGIFR
  },
  ChartFooter: {
    "en-CA": emailDisclaimerEN,
    "fr-CA": emailDisclaimerFR
  },
  FTVFooter: {
    "en-CA":
      "Insurance products are issued by The Manufacturers Life Insurance Company. Manulife's Rates to Go illustrates insurance premiums based on the information provided. This quote does not provide an offer to insure. Applications are subject to underwriting. Premium rates are subject to change without notice. The premium savings can change every year during the premium adjustment period, depending on the insured person’s Vitality Status™. Vitality and Vitality Status™ are trademarks of The Vitality Group International, Inc., and are used by The Manufacturers Life Insurance Company and its affiliates under license. © 2023 Manulife. All rights reserved.",
    "fr-CA":
      "Les produits d’assurance sont établis par La Compagnie d’Assurance-Vie Manufacturers. Les Taux Express de Manuvie indiquent les primes d’assurance en fonction des renseignements fournis. Ce devis ne constitue pas une proposition d’assurance. Les demandes sont assujetties à la tarification. Les taux de prime peuvent être modifiés sans préavis. Les rabais sur les primes peuvent changer chaque année durant la période de rajustement de la prime, selon le niveau 𝑉𝑖𝑡𝑎𝑙𝑖𝑡𝑒́ᴹᶜ de la personne assurée. Les noms Vitalité et Niveau 𝑉𝑖𝑡𝑎𝑙𝑖𝑡𝑒́ᴹᶜ sont des marques de commerce de Vitality Group International, Inc., que La Compagnie d’Assurance-Vie Manufacturers et ses sociétés affiliées utilisent sous licence. © Manuvie, 2023. Tous droits réservés.",
  },
  LCFooter: {
    "en-CA":
      "Insurance products are issued by The Manufacturers Life Insurance Company. Manulife's Rates to Go illustrates insurance premiums based on the information provided. This quote does not provide an offer to insure. Applications are subject to underwriting. Premium rates are subject to change without notice. The illustrated premiums include the costs for the Return of Premium on Death rider. © 2023 Manulife. All rights reserved.",
    "fr-CA":
      "Les produits d’assurance sont établis par La Compagnie d’Assurance-Vie Manufacturers. Les Taux Express de Manuvie indiquent les primes d’assurance en fonction des renseignements fournis. Ce devis ne constitue pas une proposition d’assurance. Les demandes sont assujetties à la tarification. Les taux de prime peuvent être modifiés sans préavis. Les primes du projet informatisé comprennent les coûts de la garantie Remboursement des primes au décès. © Manuvie, 2023. Tous droits réservés",
  },
  SYNFooter: {
    "en-CA": emailDisclaimerEN,
    "fr-CA": emailDisclaimerFR
  },
  [eSex.male]: {
    "en-CA": "Male",
    "fr-CA": "Homme",
  },
  [eSex.female]: {
    "en-CA": "Female",
    "fr-CA": "Femme",
  },
  [eHealthStyle.hs3]: "3",
  [eHealthStyle.hs5]: "5",
  [eSmokingStatus.nonsmoker]: {
    "en-CA": "Non-smoker",
    "fr-CA": "Non fumeur",
  },
  [eSmokingStatus.smoker]: {
    "en-CA": "Smoker",
    "fr-CA": "Fumeur",
  },
  termRider:{
    "en-CA": "Term rider amount : {{termRider}}",
    "fr-CA": "Montant de la Garantie Assurance temporaire : {{termRider}}",
  },
  childNum:{
    "en-CA": "Child {{Num}}",
    "fr-CA": "Enfant {{Num}}",
  },
  lifeInsurence:{
    "en-CA": "Child protection rider - Life: $10,000",
    "fr-CA": "Garantie Protection des enfants – Assurance vie: 10 000 $",
  },
  criticalIllness:{
    "en-CA": "Child protection rider - CI : {{ci}} ",
    "fr-CA": "Garantie Protection des enfants – Assurance maladies graves : {{ci}}",
  }
};
export default EmailTemplate;
