import styled from "styled-components";

const TabContent = styled.div`
  background-color: white;
`;

const GraphLine = styled.div`
  width: 27px;
  height: 4px;
`;

const ContentWrapper = styled.div`
  text-align: left;
  & p {
    margin-bottom: 1rem;
  }
`;

const DisclaimerWrapper = styled.div`
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export {
  TabContent,
  GraphLine,
  ContentWrapper,
  DisclaimerWrapper
};

