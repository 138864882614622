import { UtilityHeader, useLang } from "@manulife/mux";
import { changeLang } from "../../services/helpers/commonHelpers";

export default function RtgAppHeader() {
  const selectedLang = useLang();

  return (
    <section id="utHeader">
      <UtilityHeader>
        <UtilityHeader.Lang onClick={() => changeLang(selectedLang)} />
      </UtilityHeader>
    </section>
  );
}
