import { H1, MessageCard, P, VIEWPORTS, rem, useLang } from '@manulife/mux';
import { ButtonXIcon } from '@manulife/mux-cds-icons';
import Labels from "../../assets/labels";
import useWindowSize from '../../customHooks/useWindowSize';
import { colors } from '@manulife/mux';
import { useSelector } from 'react-redux';
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

export default function RtgPageHeader() {
    const {num40, num15} = NumberConstants;
    const selectedLang = useLang();
    const { width: windowWidth } = useWindowSize();
    const isError = useSelector(
        (state) => state.errorReducer?.isError
    );
    let customStyle = {
        elementStyle: {
            padding: `${rem(0)}`
        },
    };
    if (windowWidth < VIEWPORTS.XS) {
        customStyle = {
            elementStyle: {
                padding: `${rem(0)} ${rem(num15)}`
            },
        }
    };
    return (
        <>
            {isError && <MessageCard
                className={windowWidth < VIEWPORTS.XS ? "rtgErrorMessageCardXs" : "rtgErrorMessageCard"}
                variant={MessageCard.VARIANT.PRIMARY_ALERT}
                hasCloseButton
                closeIcon={<ButtonXIcon color={colors.dark_navy} fontSize={rem(num40)} />}
            >
                {Labels.errors.apiFailed[selectedLang]}
            </MessageCard>}
            <H1 role="heading" aria-level="1" customStyle={customStyle}>{Labels.title[selectedLang]}</H1>
            <P customStyle={customStyle}>{Labels.subtitle[selectedLang]}</P>
        </>
    )
};
