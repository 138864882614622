import { LANG } from "@manulife/mux";
import { eCostType, eProduct } from "./udmGenerator";
import Labels from "../../assets/labels"
import AppURL from "../../assets/url.json";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

const replaceKeysWithValues = (str, valueObj) => {
  let newStr = str;
  const keys = Object.keys(valueObj);
  keys.forEach((key) => {
    let regex = new RegExp(`{{${key}}}`, "g");
    newStr = newStr.replace(regex, valueObj[key]);
  });
  return newStr;
};

const formatAmount = (amount = 0, selectedLang) => {
  switch (selectedLang) {
    case LANG.EN_CA:
      return `$${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    case LANG.FR_CA:
      return `${amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",")} $`;

    default:
      return 0;
  }
};

const getOnlyNumbers = (value, isZeroAllowedInFront = false) => {
  if (isZeroAllowedInFront) {
    return value.toString().replace(/[^0-9]+/g, "");
  }
  return value
    .toString()
    .replace(/[^0-9]+/g, "")
    .replace(/^0+/g, "");
};

const getAmountNumbers = (amount = 0, selectedLang) => {
  return amount
    .toString()
    .replace(/[^0-9-]+/g, "")
    .replace(/^0+/g, "");
};

const addCurrencySign = (amountInNumber, selectedLang) => {
  switch (selectedLang) {
    case LANG.EN_CA:
      return `$ ${amountInNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    case LANG.FR_CA:
      return `${amountInNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} $`;

    default:
      return 0;
  }
};

const getMinMaxAmountLimit = (selectedProduct, ageGI) => {
  const {num70, num75, num10000, num100000, num25000000 , num250000, num25000, num50000, num500000, num2000000} = NumberConstants;
  switch (selectedProduct) {
    case eProduct.GI:
      return { min: num10000, max: ageGI <= num70 || ageGI > num75 ? num100000 : num50000 };
    case eProduct.FT:
      return { min: num100000, max: num25000000 };
    case eProduct.FTV:
      return { min: num250000, max: num25000000 };
    case eProduct.LC:
      return { min: num25000, max: num2000000 };
    case eProduct.SYN:
      return { min: num100000, max: num500000 };
    default:
      return { min: num100000, max: num25000000 };
  }
};

const getMinMaxAgeLimit = (selectedCostType, selectedProduct) => {
  const {num18, num60 , num54, num45, num55, num50, num70, num75, num100} =NumberConstants;
  if (selectedProduct === eProduct.GI) {
    return { min: num18, max: num75 };
  }
  switch (selectedCostType) {
    case eCostType.LC_T10:
      return { min: num18, max: num60 };
    case eCostType.LC_T20:
      return { min: num18, max: num54 };
    case eCostType.LC_T65:
      return { min: num18, max: num45 };
    case eCostType.LC_T75:
      return { min: num18, max: num60 };
    case eCostType.LC_T100:
      return { min: num18, max: num60 };
    case eCostType.LC_PAY15:
      return { min: num18, max: num55 };
    case eCostType.SYN_T10:
      return { min: num18, max: num50 };
    case eCostType.SYN_LEVEL:
      return { min: num18, max: num50 };
    case eCostType.FT_FTV_T10:
      return { min: num18, max: num70 };
    case eCostType.FT_FTV_T20:
      return { min: num18, max: num60 };
    case eCostType.FT_FTV_T65:
      return { min: num18, max: num45 };
    default:
      return { min: num18, max: num100 };
  }
};

const getCostTypeToRender = (selectedCostType, selectedLang) => {
  switch (selectedCostType) {
    case eCostType.LC_T10:
      return "T10";
    case eCostType.LC_T20:
      return "T20";
    case eCostType.LC_T65:
      return "T65";
    case eCostType.LC_T75:
      return "T75";
    case eCostType.LC_T100:
      return "T100";
    case eCostType.LC_PAY15:
      return Labels.Pay[selectedLang];
    case eCostType.SYN_T10:
      return "T10";
    case eCostType.SYN_LEVEL:
      return Labels.Level[selectedLang];
    case eCostType.FT_FTV_T10:
      return "T10";
    case eCostType.FT_FTV_T20:
      return "T20";
    case eCostType.FT_FTV_T65:
      return "T65";
    default:
      return "";
  }
};

const changeLang = (selectedLang) => {
  const CURR_ENV_URL_OBJ = AppURL.find(ele => ele[selectedLang].includes(window.location.host));
  window.open(CURR_ENV_URL_OBJ[selectedLang === LANG.EN_CA ? LANG.FR_CA : LANG.EN_CA], "_self");
};

const genrateRandomeString = (strLength = 9) => {
  const characterSet = '0123456789ABCDEF';
  let randomString = '';
  while (randomString.length <= strLength) {
    randomString += characterSet[Math.floor(Math.random() * characterSet.length)];
  }
  return randomString;
}

export {
  replaceKeysWithValues,
  formatAmount,
  getAmountNumbers,
  addCurrencySign,
  getMinMaxAmountLimit,
  getMinMaxAgeLimit,
  getOnlyNumbers,
  getCostTypeToRender,
  changeLang,
  genrateRandomeString
};
