import React from 'react';

import Labels from "../../../assets/labels";
import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown, useLang } from '@manulife/mux';

function RtgSelectedProduct() {
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods
    return (
        <>
            <Controller
                control={control}
                name="rtgSelectedProduct"
                rules={{
                    required: Labels.errors.required[`${selectedLang}`]
                }}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                }) => (
                    <Dropdown
                        id={name}
                        dropdownItems={Labels.product[`${selectedLang}`].options}
                        placeholder={Labels.select[`${selectedLang}`].label}
                        label={Labels.product[`${selectedLang}`].label}
                        data-testid={Labels.product[`${selectedLang}`].label}
                        ariaLabel={Labels.product[`${selectedLang}`].label}
                        ariaRequired={true}
                        customStyle={{
                            rootStyle: {
                                width: "100%",
                            },
                            labelStyle: {},
                            dropdownInputStyle: {},
                            dropdownMenuStyle: {

                            },
                        }}
                        ref={ref}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                    />
                )}
            />
        </>
    );
};

export default RtgSelectedProduct;