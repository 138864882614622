import { SideNav, useLang } from "@manulife/mux";
import { changeLang } from "../../services/helpers/commonHelpers";

function RtgSideNav() {
  const selectedLang = useLang();

  return (
    <SideNav
      activeKey="/item/1"
      shouldCloseOnBlur={false}
      mobileFooterItems={[<SideNav.Lang key="lang" onClick={() => changeLang(selectedLang)} />]}

    ></SideNav>
  );
}

export default RtgSideNav;
