import { Footer, useLang } from "@manulife/mux";
import Labels from "../../assets/labels";

export default function RtgFooter() {
  const selectedLang = useLang();
  const items = [
    {
      id: "privacy",
      title: Labels.Privacy[selectedLang].label,
      href: Labels.Privacy[selectedLang].url,
      isExternal: true,
    },
    {
      id: "legal",
      title: Labels.Legal[selectedLang].label,
      href: Labels.Legal[selectedLang].url,
      isExternal: true,
    },
    {
      id: "accessiblity",
      title: Labels.Accessibility[selectedLang].label,
      href: Labels.Accessibility[selectedLang].url,
      isExternal: true,
    },
  ];
  return (
    <section id="utFooter">
      <Footer role="region" aria-label="Footer"
        items={items}
        copyrightText={Labels.copyrightTextFooter[selectedLang]}
        copyrightYear=""
        copyrightYearAria=""
      />
    </section>
  );
}
