import { rem, typography } from "@manulife/mux";
import styled from "styled-components";
import NumberConstants from "./numberConstant.json";

const {num24, num32, num44, num16, num14, num20, num18, num26, num12} = NumberConstants;

const BoldValueText = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num32)};
    line-height: ${rem(num44)};
`;

const BoldLebalText = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num16)};
    line-height: ${rem(num24)};
`;

const NormalText = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};
    font-size: ${rem(num16)};
    line-height: ${rem(num24)};
`;

const NormalTextW400 = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightNormal};
    font-size: ${rem(num16)};
    line-height: ${rem(num24)};
`;

const NormalText14 = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};
    font-size: ${rem(num14)};
    line-height: ${rem(num20)};
`;

const FooterBtnLabel = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num18)};
    line-height: ${rem(num26)};
`;
const ChartIndexLabel = styled.h2`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num18)};
    line-height: ${rem(num26)};
`;

const ModalHeader = styled.h2`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};
    font-size: ${rem(num32)};
    line-height: ${rem(num44)};
`;

const ChildHeader = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};
    font-size: ${rem(num32)};
    line-height: ${rem(num44)};
`;

const BoldChartText = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num12)};
    line-height: ${rem(num24)};
    text-align: center;
`;

const BoldChartHeading = styled.h2`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num16)};
    line-height: ${rem(num24)};
    text-align: center;
`;

const BoldChartTitle = styled.p`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num16)};
    line-height: ${rem(num24)};
    text-align: center;
`;

export {
    ChartIndexLabel,
    BoldValueText,
    BoldLebalText,
    BoldChartText,
    BoldChartHeading,
    BoldChartTitle,
    NormalText,
    NormalTextW400,
    NormalText14,
    FooterBtnLabel,
    ModalHeader,
    ChildHeader
};

/***
 * 
 * Font Family
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * * * typography.fontFamily for Manulife JH Sans
 * * * typography.fontFamilySerif for Manulife JH Serif
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * 
 * Font Size
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * * * typography.fontSize for 1rem
 * * * typography.fontSizeSmall for 0.875rem
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * 
 * Font Style
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * * * typography.fontStyle for normal
 * * * typography.fontStyleItalic for italic
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * 
 * Font Weight
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * * * typography.fontWeightLight for 300
 * * * typography.fontWeightNormal for 400
 * * * typography.fontWeightDemibold for 600
 * * * typography.fontWeightBold for 700
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * 
 */