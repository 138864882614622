import React, { useEffect } from "react";
import {
  BREAKPOINTS,
  Card,
  Stack,
  VIEWPORTS,
  rem,
  useLang,
} from "@manulife/mux";
import { useForm, FormProvider } from "react-hook-form";
import { NormalText } from "../../assets/appStyledComponents";
import useWindowSize from "../../customHooks/useWindowSize";

import Labels from "../../assets/labels";
import RtgResult from "../RtgResult/RtgResult";
import RtgInputs from "../RtgInputs/RtgInputs";
import {
  addCurrencySign,
  getAmountNumbers,
} from "../../services/helpers/commonHelpers";

import { useDispatch } from "react-redux";
import { fetchReport } from "../../appRedux/report/Actions";

import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

function RtgPageBody() {
  const { num30, num40, num15, num20 } = NumberConstants;
  const selectedLang = useLang();
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();

  let cardWidth = { width: "78vw" };
  if (windowWidth >= BREAKPOINTS.MAX_1280) {
    cardWidth = { width: "47%" };
  }

  const methods = useForm({
    defaultValues: {
      rtgSelectedProduct: "",
      rtgCostType: "",
      rtgAmount: "",
      rtgAge: "",
      rtgSex: "",
      rtgHealthStyle: "",
      rtgSynClientName: "",
      rtgSynTermRiderAmount: "",
      rtgSynChildProtectionRiders: "",
      rtgSynChildCount: "",
      rtgSynChilds: [],
    },
  });

  const {
    formState: { isSubmitted },
    handleSubmit,
    trigger,
    getValues,
    setValue,
    watch,
  } = methods;

  const rtgCostType = watch("rtgCostType");

  useEffect(() => {
    const amount1ToCheck = getAmountNumbers(
      getValues("rtgAmount") || 0,
      selectedLang
    );
    if (Number(amount1ToCheck) > 0) {
      setValue("rtgAmount", addCurrencySign(amount1ToCheck, selectedLang));
    }
    const amount2ToCheck = getAmountNumbers(
      getValues("rtgSynTermRiderAmount") || 0,
      selectedLang
    );
    if (Number(amount2ToCheck) > 0) {
      setValue(
        "rtgSynTermRiderAmount",
        addCurrencySign(amount2ToCheck, selectedLang)
      );
    }
    if (isSubmitted) {
      trigger();
    }
    return () => true;
  }, [selectedLang, rtgCostType, isSubmitted, trigger, getValues, setValue]);

  const fetchCalculation = (data) => {
    const {
      rtgSelectedProduct,
      rtgCostType,
      rtgAmount,
      rtgAge,
      rtgSex,
      rtgHealthStyle,
      rtgSynClientName,
      rtgSynTermRiderAmount,
      rtgSynChildProtectionRiders,
      rtgSynChildCount,
      rtgSynChilds,
    } = data;

    dispatch(
      fetchReport({
        product: rtgSelectedProduct,
        costType: rtgCostType,
        amount: getAmountNumbers(rtgAmount, selectedLang),
        age: rtgAge,
        gender: rtgSex,
        healthStyle: rtgHealthStyle,
        clientName: rtgSynClientName,
        termRiderAmount: getAmountNumbers(
          rtgSynTermRiderAmount || 0,
          selectedLang
        ),
        childProtectionRiders: rtgSynChildProtectionRiders,
        childCount: rtgSynChildCount,
        childs: rtgSynChilds,
        selectedLang,
      })
    );
  };

  return (
    <Card customStyle={{ cardStyle: { marginBottom: `${rem(num30)}` } }}>
      <Card.Content
        customStyle={{
          contentStyle: {
            padding:
              windowWidth > VIEWPORTS.XS ? `${rem(num40)}` : `${rem(num15)}`,
          },
        }}
      >
        <NormalText>{Labels.text[`${selectedLang}`].label}</NormalText>
        <FormProvider {...methods}>
          {/* pass all methods into the context */}
          <Stack direction="row" gap={rem(num40)} align="flex-start" justify="space-evenly">
            <Stack
              as="form"
              onSubmit={handleSubmit(fetchCalculation)}
              gap={rem(num20)}
              align="flex-start"
              justify="flex-start"
              style={{ ...cardWidth }}
            >
              <RtgInputs />
            </Stack>
            <Card
              customStyle={{
                cardStyle: { ...cardWidth, alignSelf: "stretch" },
              }}
              data-testid="resultColumn"
            >
              <RtgResult />
            </Card>
          </Stack>
        </FormProvider>
      </Card.Content>
    </Card>
  );
}

export default RtgPageBody;
