import { Tabs, useLang } from "@manulife/mux";
import React, { useEffect, useState } from "react";
import "./RtgResult.css";
import InitialPremiumTab from "./InitialPremiumTab";
import ResultLables from "../../assets/resultLabels.json";
import { useDispatch, useSelector } from "react-redux";
import { isObjectEmpty } from "../../services/helpers/objectUtilities";
import { TabContent } from "../../assets/appStyledComponents/styledDivComponents";
import { eProduct } from "../../services/helpers/udmGenerator";
import NewPurchaseTab from './NewPurchaseTab';
import RenewalTab from './RenewalTab';
import { fetchChartSuccess } from "../../appRedux/chart/Actions";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

function RtgResult() {
  const selectedLang = useLang();
  const {num17, num46} = NumberConstants;
  const dispatch = useDispatch();
  const illustrationData = useSelector(
    (state) => state.rportReducer?.data
  );
  const [currentTab, setCurrentTab] = useState("Tab-1");

  const initialPremiumTabs = [
    {
      id: "tab-1",
      label: ResultLables.initialPremium[`${selectedLang}`].label,
      value: "Tab-1",
    },
  ];

  const newPurchaseRenewalTabs = [
    {
      id: "tab-2",
      label: ResultLables.newPurchase[`${selectedLang}`].label,
      value: "Tab-2",
    },
    {
      id: "tab-3",
      label: ResultLables.renewal[`${selectedLang}`].label,
      value: "Tab-3",
    },
  ];

  useEffect(() => {
    setCurrentTab("Tab-1");
    dispatch(fetchChartSuccess({}));
  }, [illustrationData, dispatch]);

  const tabs =
    (!isObjectEmpty(illustrationData) &&
      !("vitality" in (illustrationData?.illustration?.policy || {})) &&
      illustrationData?.illustration?.policy?.product === eProduct.FT &&
      illustrationData?.illustration?.party[0]?.age > num17 &&
      illustrationData?.illustration?.party[0]?.age < num46)
      ? [...initialPremiumTabs, ...newPurchaseRenewalTabs]
      : initialPremiumTabs;
  return (
    <div className={tabs.length <= 1 ? "noActiveBorder" : ""}>
      <Tabs
        tabItems={tabs}
        variant={Tabs.VARIANT.GREY}
        tabAlignment={Tabs.ALIGNMENT.LEFT}
        selectedTab={currentTab}
        onChange={(val) => {
          setCurrentTab(val);
        }}
      >
        <Tabs.Panel>
          <TabContent>
            <InitialPremiumTab />
          </TabContent>
        </Tabs.Panel>
        <Tabs.Panel>
          <TabContent>
            <NewPurchaseTab />
          </TabContent>
        </Tabs.Panel>
        <Tabs.Panel>
          <TabContent>
            <RenewalTab />
          </TabContent>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

export default RtgResult;
