import { LANG } from "@manulife/mux";
import moment from "moment/moment";

export const eProduct = {
  ALL: "All",
  GI: "GuaranteedIssue",
  FT: "FamilyTerm",
  FTV: "FamilyTermVitality",
  LC: "Lifecheque",
  SYN: "Synergy",
};

export const eCostType = {
  FT_FTV_T10: "Term10",
  FT_FTV_T20: "Term20",
  FT_FTV_T65: "Term65",
  LC_T10: "LifechequeRenewable10",
  LC_T20: "LifechequeRenewable20",
  LC_T65: "LifechequePrimary65",
  LC_T75: "LifechequeLevel75",
  LC_T100: "LifechequePermanent",
  LC_PAY15: "LifechequePermanent-15Pay",
  SYN_T10: "Renewable10",
  SYN_LEVEL: "Level65",
};

export const eIsChildProtection = {
  yes: "YES",
  no: "NO",
};

export const eInsuredTypes = {
  LI: "LifeInsurance10k",
  CI: "CriticalIllness",
};

export const eCriticalIllnessOpt = {
  k10: "10000",
  k5: "5000",
};

export const eSex = {
  male: "Male",
  female: "Female",
};

export const eSmokingStatus = {
  nonsmoker: "NonSmoker",
  smoker: "Smoker",
};

export const eHealthStyle = {
  hs3: "HS3",
  hs5: "HS5",
};

const eIndicatorCode = {
  base: "Base",
  termRider: "TermRider",
  rider: "Rider",
};

const eUDMType = {
  validation: "Validation",
  illustration: "Full",
  coverageCompare: "CoverageCompare",
  summary: "Summary",
};

const eUserType = {
  advisor: "Advisor",
  headOffice: "HeadOffice",
};

const eCoverageType = {
  single: "Single",
  combined: "CombinedTir",
  jointFirst: "JointFtd",
  jointLast: "JointLtd",
  jointLastPayLastDeath: "JointLtdCtl",
};

const eBusinessType = {
  NB: "NewBusiness",
  IF: "Inforce",
};

const eSystemType = {
  R2G: "RatesToGo",
  WEB: "Web",
};

const getLang = (selectedLang) =>
  selectedLang === LANG.EN_CA ? "English" : "French";

const getUser = (type = eUserType.advisor) => ({ type });

const getParty = (partyId, age, gender, firstName = "", lastName = "") => ({
  partyId,
  age,
  gender,
  firstName,
  lastName,
});

const getReportOptions = (language, type = "Base", outputType = "Pdf") => ({
  language,
  type,
  outputType,
});

const getLife = (partyId, age, healthstyle = null) => ({ partyId, age, healthstyle });

const getPremiumDuration = (type = "Expiry") => ({ type });

const getCoverage = (
  coverageId,
  product,
  lifeArray,
  coverageAmount = null,
  indicatorCode = eIndicatorCode.base,
  coverageType = eCoverageType.single
) => ({
  coverageId,
  product,
  coverageType,
  coverageAmount,
  indicatorCode,
  life: lifeArray,
});

const getLifeMapping = (partyId, status = ["G:1"]) => ({
  lifeId: partyId,
  status,
});

const getCoverageMapping = (partyId, coverageId) => ({
  coverageId,
  lifeId: partyId,
});

const getVitality = (
  lifeMappingArray,
  coverageMappingArray,
  type = "Plus"
) => ({ type, life: lifeMappingArray, coverage: coverageMappingArray });

const getPolicy = (
  product,
  productFamily,
  coverageArray,
  vitalityObj = null,
  businessType = eBusinessType.NB
) => ({
  product,
  productFamily,
  businessType,
  coverage: coverageArray,
  vitality: vitalityObj,
  depositMode: "Annually",
});

const getIllustration = (
  language,
  user,
  partyArray,
  reportOptions,
  policyObj,
  system = eSystemType.R2G,
  illustrationId = null,
  name = null
) => ({
  language,
  user,
  party: partyArray,
  reportOptions,
  policy: policyObj,
  system,
  illustrationId,
  name,
  creationDate: moment().format("YYYY-MM-DD"),
});

const getUDM = (
  illustrationObj,
  type = eUDMType.summary,
  Id = null,
  requestId = null
) => ({ illustration: illustrationObj, type, Id, requestId });

const getCoverageIdPrefix = (selectedProduct) => {
  switch (selectedProduct) {
    case eProduct.FT:
    case eProduct.FTV:
      return "Term.";
    case eProduct.LC:
      return "Lc.";
    case eProduct.SYN:
      return "Synergy.";
    case eProduct.GI:
      return "GuaranteedIssue.";
    default:
      return "";
  }
};

const getProductName = (selectedProduct) => {
  switch (selectedProduct) {
    case eProduct.FT:
    case eProduct.FTV:
      return "FamilyTerm";
    case eProduct.LC:
      return "Lifecheque";
    case eProduct.SYN:
      return "Synergy";
    case eProduct.GI:
      return "GuaranteedIssue";
    default:
      return "";
  }
};

const getProductFamily = (selectedProduct) => {
  switch (selectedProduct) {
    case eProduct.FT:
    case eProduct.FTV:
      return "Term";
    case eProduct.LC:
      return "Lifecheque";
    case eProduct.SYN:
      return "Synergy";
    case eProduct.GI:
      return "GuaranteedIssue";
    default:
      return "";
  }
};

const genratePremiumDuration = (selectedCostType) => {
  switch (selectedCostType) {
    case eCostType.LC_PAY15:
      return getPremiumDuration("Year15");
    case eCostType.LC_T100:
      return getPremiumDuration("Age100");
    default:
      return getPremiumDuration();
  }
};

const genrateReturnOfPremium = (type = "Ropd") => [{ type }];

const genrateVitality = (partyArray, coverageArray) => {
  const [{ partyId }] = partyArray;
  const [{ coverageId }] = coverageArray;
  const lifeMappingArray = [getLifeMapping(partyId)];
  const coverageMappingArray = [getCoverageMapping(partyId, coverageId)];
  return getVitality(lifeMappingArray, coverageMappingArray);
};

const genrateCoverage = (requestParam, partyArray) => {
  const { product, costType, amount, healthStyle, termRiderAmount = "" } = requestParam;
  let coverageIdPrefix = getCoverageIdPrefix(product);
  let coverageArray = [];
  switch (product) {
    case eProduct.LC:
      coverageArray = partyArray.map((party, index) => {
        const { partyId, age } = party;
        return {
          ...getCoverage(
            `${coverageIdPrefix}${index}`,
            costType === eCostType.LC_PAY15 ? eCostType.LC_T100 : costType,
            [getLife(partyId, age, healthStyle)],
            amount
          ),
          premiumDuration: genratePremiumDuration(costType),
          returnOfPremium: genrateReturnOfPremium(),
        };
      });
      break;
    case eProduct.GI:
      coverageArray = partyArray.map((party, index) => {
        const { partyId, age } = party;
        return {
          ...getCoverage(
            `${coverageIdPrefix}${index}`,
            `GuaranteedIssue`,
            [getLife(partyId, age, healthStyle)],
            amount
          ),
        };
      });
      break;
    case eProduct.SYN:
      partyArray.forEach((party, index) => {
        const { partyId, age, childData } = party;
        if (partyId.startsWith("Life.")) {
          coverageArray.push({
            ...getCoverage(
              `${coverageIdPrefix}${index + 1}`,
              `Synergy`,
              [getLife(partyId, age, healthStyle)],
              amount
            ),
            costOfInsurance: {
              type: costType
            },
          });
          if (Number(termRiderAmount) > 0) {
            coverageArray.push({
              ...getCoverage(
                `Term.${index + 1}`,
                "Term10",
                [getLife(partyId, age, healthStyle)],
                termRiderAmount,
                eIndicatorCode.termRider
              )
            })
          }
        }
        if (partyId.startsWith("Child.") && childData.insuredTypes?.[eInsuredTypes.LI]) {
          coverageArray.push({
            ...getCoverage(
              `Cpr.${index + 1}`,
              "CPR",
              [getLife(partyId, age)],
              null,
              eIndicatorCode.rider
            )
          });
        }
        if (partyId.startsWith("Child.") && childData.insuredTypes?.[eInsuredTypes.CI]) {
          coverageArray.push({
            ...getCoverage(
              `Cprci.${index + 1}`,
              "CPRCI",
              [getLife(partyId, age)],
              childData.criticalIllnessAmount,
              eIndicatorCode.rider
            )
          });
        }
      });
      break;
    default:
      coverageArray = partyArray.map((party, index) => {
        const { partyId, age } = party;
        return getCoverage(`${coverageIdPrefix}${index}`, costType, [getLife(partyId, age, healthStyle)], amount);
      });
  }
  return coverageArray;
};

const genratePolicy = (requestParam, partyArray) => {
  const { product } = requestParam;
  const coverageArray = genrateCoverage(requestParam, partyArray);
  if (product === eProduct.FTV) {
    const vitalityObj = genrateVitality(partyArray, coverageArray);
    return getPolicy(
      getProductName(product),
      getProductFamily(product),
      coverageArray,
      vitalityObj
    );
  }
  return getPolicy(
    getProductName(product),
    getProductFamily(product),
    coverageArray
  );
};

export const genrateUDM = (requestParam) => {
  const { product, age, gender, childProtectionRiders, childs, selectedLang, isDownloadPDF = false, clientName = "", reportType = "Base" } = requestParam;

  const user = getUser();
  const partyArray = [getParty("Life.1", age, gender, clientName)];

  if (product === eProduct.SYN && childProtectionRiders === eIsChildProtection.yes) {
    childs.map((child, index) => {
      const { name, age, sex } = child;
      partyArray.push({ ...getParty(`Child.${index + 1}`, age, sex, (name === "" || name === undefined) ? `${selectedLang === LANG.EN_CA ? "Child" : "Enfant"} ${index + 1}` : name), childData: child });
      return true;
    })
  }

  const reportOptions = getReportOptions(getLang(selectedLang), reportType);
  const policyObj = genratePolicy(requestParam, partyArray);
  const illustrationObj = getIllustration(
    getLang(selectedLang),
    user,
    partyArray.map(party => {
      delete party.childData;
      return { ...party }
    }),
    reportOptions,
    policyObj,
    product === eProduct.GI ? eSystemType.WEB : eSystemType.R2G
  );

  if (isDownloadPDF) {
    return getUDM(illustrationObj, eUDMType.illustration);
  }

  return getUDM(illustrationObj);
};
