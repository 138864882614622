import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ChildHeader } from '../../assets/appStyledComponents';

import RtgSynChildName from './Fields/RtgSynChildName';
import RtgSynChildAge from './Fields/RtgSynChildAge';
import RtgSex from './Fields/RtgSex';
import RtgSynChildInsuredType from './Fields/RtgSynChildInsuredType';
import RtgSynChildCriticalIllnessAmount from './Fields/RtgSynChildCriticalIllnessAmount';
import { isObjectEmpty } from '../../services/helpers/objectUtilities';
import Labels from '../../assets/labels';
import { useLang } from '@manulife/mux';

function SynergyChildInputs({ chIndex }) {
    const selectedLang = useLang();

    const { formState: { isSubmitted }, watch, trigger, setValue } = useFormContext(); // retrieve all hook methods

    const rtgSynChildsArray = watch("rtgSynChilds");
    const insuredTypes = watch(`rtgSynChilds.${chIndex}.insuredTypes`);

    useEffect(() => {
        if (isSubmitted) {
            trigger();
        }

        if (!insuredTypes?.CriticalIllness) {
            setValue(`rtgSynChilds.${chIndex}.criticalIllnessAmount`, "");
        }

        if (!insuredTypes?.CriticalIllness && !insuredTypes?.LifeInsurance10k && !isObjectEmpty(insuredTypes)) {
            setValue(`rtgSynChilds.${chIndex}.insuredTypes`, {});
        }

        return () => true;
    }, [insuredTypes, chIndex, trigger, setValue, isSubmitted]);

    return (
        <>
            <ChildHeader aria-label={`${Labels.child[`${selectedLang}`]} ${chIndex + 1}`}  style={{ margin: 0 }}>{Labels.child[`${selectedLang}`]} {chIndex + 1}</ChildHeader>

            <RtgSynChildName chIndex={chIndex} />
            <RtgSynChildAge chIndex={chIndex} />
            <RtgSex fieldName={`rtgSynChilds.${chIndex}.sex`} />
            <RtgSynChildInsuredType chIndex={chIndex} />

            {rtgSynChildsArray?.[chIndex]?.insuredTypes?.CriticalIllness ? <RtgSynChildCriticalIllnessAmount chIndex={chIndex} /> : null}
        </>
    )
};

export default SynergyChildInputs;