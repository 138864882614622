import { formatDate } from "../services/date/dateProcessors";
import { formatAmount, getCostTypeToRender, replaceKeysWithValues } from "../services/helpers/commonHelpers";
import { generatePdfUrl } from "../services/helpers/download";
import EmailTemplate from "./emailContent";
import NumberConstants from "../assets/appStyledComponents/numberConstant.json";

export const emailFtChartBody = async (data, lang) => {
  return `${replaceKeysWithValues(EmailTemplate.bodyLine1[lang], { current_date: formatDate("emailTemplateDate", lang) })}

${await generatePdfUrl(`/apix/download/chartPDFReport/`, data)}

${EmailTemplate.ChartFooter[lang]}`;
};

export const emailGIBody = (data, lang, validatedData) => {
  const {num2} =NumberConstants;
  const { amount, age, healthStyle, gender, costType } = validatedData;
  return `${replaceKeysWithValues(EmailTemplate.bodyLine1[lang], { current_date: formatDate("emailTemplateDate", lang) })}

${replaceKeysWithValues(EmailTemplate.monthlyPremium[lang], { monthly_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Monthly").policyModalPremium).toFixed(num2) : "0", lang) })}
${replaceKeysWithValues(EmailTemplate.annualPremium[lang], { annual_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Annually").policyModalPremium).toFixed(num2) : "0", lang) })}

${replaceKeysWithValues(EmailTemplate.productWithCostTypeGI[lang], { costType: getCostTypeToRender(costType, lang) })}
${replaceKeysWithValues(EmailTemplate.coverageAmount[lang], { coverage_amount: formatAmount(amount, lang), lang })}
${replaceKeysWithValues(EmailTemplate.age[lang], { age: age, lang })}
${replaceKeysWithValues(EmailTemplate.sex[lang], { sex: EmailTemplate[gender][lang] })}
${replaceKeysWithValues(EmailTemplate.smokingStatus[lang], { smokingStatus: EmailTemplate[healthStyle][lang] })}

${EmailTemplate.GIFooter[lang]}`;
};

export const emailFtBody = (data, lang, validatedData) => {
  const {num2} =NumberConstants;
  const { amount, age, healthStyle, gender, costType } = validatedData;
  return `${replaceKeysWithValues(EmailTemplate.bodyLine1[lang], { current_date: formatDate("emailTemplateDate", lang) })}

${replaceKeysWithValues(EmailTemplate.monthlyPremium[lang], { monthly_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Monthly").policyModalPremium).toFixed(num2) : "0", lang) })}
${replaceKeysWithValues(EmailTemplate.annualPremium[lang], { annual_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Annually").policyModalPremium).toFixed(num2) : "0", lang) })}

${replaceKeysWithValues(EmailTemplate.productWithCostTypeFT[lang], { costType: getCostTypeToRender(costType, lang) })}
${replaceKeysWithValues(EmailTemplate.coverageAmount[lang], { coverage_amount: formatAmount(amount, lang), lang })}
${replaceKeysWithValues(EmailTemplate.age[lang], { age: age, lang })}
${replaceKeysWithValues(EmailTemplate.sex[lang], { sex: EmailTemplate[gender][lang] })}
${replaceKeysWithValues(EmailTemplate.healthstyle[lang], { healthstyle: EmailTemplate[healthStyle] })}

${EmailTemplate.FTFooter[lang]}`;
};

export const emailFtvBody = (data, lang, validatedData) => {
  const {num2} = NumberConstants;
  const { amount, age, healthStyle, gender, costType } = validatedData;
  return `${replaceKeysWithValues(EmailTemplate.bodyLine1[lang], { current_date: formatDate("emailTemplateDate", lang) })}

${replaceKeysWithValues(EmailTemplate.monthlyPremium[lang], { monthly_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Monthly").policyModalPremium).toFixed(num2) : "0", lang) })}
${replaceKeysWithValues(EmailTemplate.annualPremium[lang], { annual_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Annually").policyModalPremium).toFixed(num2) : "0", lang) })}

${replaceKeysWithValues(EmailTemplate.productWithCostTypeFTV[lang], { costType: getCostTypeToRender(costType, lang) })}
${replaceKeysWithValues(EmailTemplate.coverageAmount[lang], { coverage_amount: formatAmount(amount, lang), lang })}
${replaceKeysWithValues(EmailTemplate.age[lang], { age: age, lang })}
${replaceKeysWithValues(EmailTemplate.sex[lang], { sex: EmailTemplate[gender][lang] })}
${replaceKeysWithValues(EmailTemplate.healthstyle[lang], { healthstyle: EmailTemplate[healthStyle] })}

${EmailTemplate.FTVFooter[lang]}`;
};

export const emailLcBody = (data, lang, validatedData) => {
  const {num2}  = NumberConstants;
  const { amount, age, healthStyle, gender, costType } = validatedData;
  return `${replaceKeysWithValues(EmailTemplate.bodyLine1[lang], { current_date: formatDate("emailTemplateDate", lang) })}

${replaceKeysWithValues(EmailTemplate.monthlyPremium[lang], { monthly_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Monthly").policyModalPremium).toFixed(num2) : "0", lang) })}
${replaceKeysWithValues(EmailTemplate.annualPremium[lang], { annual_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Annually").policyModalPremium).toFixed(num2) : "0", lang) })}

${replaceKeysWithValues(EmailTemplate.productWithCostTypeLC[lang], { costType: getCostTypeToRender(costType, lang) })}
${replaceKeysWithValues(EmailTemplate.coverageAmount[lang], { coverage_amount: formatAmount(amount, lang), lang })}
${replaceKeysWithValues(EmailTemplate.age[lang], { age: age, lang })}
${replaceKeysWithValues(EmailTemplate.sex[lang], { sex: EmailTemplate[gender][lang] })}
${replaceKeysWithValues(EmailTemplate.smokingStatus[lang], { smokingStatus: EmailTemplate[healthStyle][lang] })}

${EmailTemplate.LCFooter[lang]}`;
};

export const emailSynBody = async (data, lang, validatedData) => {
  const {num2}  = NumberConstants;
  const { amount, age, healthStyle, gender, costType, termRiderAmount = 0, childProtectionRiders, childs } = validatedData;
  return `${replaceKeysWithValues(EmailTemplate.bodyLine1[lang], { current_date: formatDate("emailTemplateDate", lang) })}

${replaceKeysWithValues(EmailTemplate.monthlyPremium[lang], { monthly_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Monthly").policyModalPremium).toFixed(num2) : "0", lang) })}
${replaceKeysWithValues(EmailTemplate.annualPremium[lang], { annual_premium: formatAmount(data.policy.premiumResult ? Number(data.policy.premiumResult.find(ele => ele.depositMode === "Annually").policyModalPremium).toFixed(num2) : "0", lang) })}

${replaceKeysWithValues(EmailTemplate.productWithCostTypeSYN[lang], { costType: getCostTypeToRender(costType, lang) })}
${replaceKeysWithValues(EmailTemplate.coverageAmountSYN[lang], { coverage_amount: formatAmount(amount, lang), lang })}
${replaceKeysWithValues(EmailTemplate.age[lang], { age: age, lang })}
${replaceKeysWithValues(EmailTemplate.sex[lang], { sex: EmailTemplate[gender][lang] })}
${replaceKeysWithValues(EmailTemplate.smokingStatus[lang], { smokingStatus: EmailTemplate[healthStyle][lang] })}${getTermRiderText(termRiderAmount, lang)}${getChildDetails(childProtectionRiders, childs, lang)}

${EmailTemplate.SYNFooter[lang]}`;
};

const getTermRiderText = (amt, lang) => {
  if (amt && amt > 0) {
    return `

${replaceKeysWithValues(EmailTemplate.termRider[lang], { termRider: formatAmount(amt, lang) })}`;
  }
  return '';
};

const getChildDetails = (childProtectionRiders, childs, lang) => {
  if (childProtectionRiders === 'YES') {
    let childResult = '';

    if (childs.length > 1) {
      childs.forEach((element, index) => {
        const { name } = element;
        childResult += index > 0 ? "\n\n" : '';
        childResult += (name === undefined || name === '' ? replaceKeysWithValues(EmailTemplate.childNum[lang], { Num: (index + 1) }) + "\n" : name + "\n");
        childResult += printChildDetail(element, lang);
      });
    } else {
      childResult += printChildDetail(childs[0], lang);
    };
    return `

${childResult}`;
  };
  return '';
};

const printChildDetail = (childData, lang) => {
  const {
    insuredTypes: { CriticalIllness = false, LifeInsurance10k = false },
    criticalIllnessAmount
  } = childData;
  let childContent = '';
  childContent += LifeInsurance10k ? EmailTemplate.lifeInsurence[lang] : '';
  childContent += LifeInsurance10k && CriticalIllness ? "\n" : '';
  childContent += CriticalIllness ? replaceKeysWithValues(EmailTemplate.criticalIllness[lang], { ci: formatAmount(criticalIllnessAmount, lang) }) : '';
  return childContent;
};
