import { combineReducers } from "redux";
import ReportReducer from "./report/Reducer";
import EnvironmentReducer from "./environment/Reducer";
import ChartReducer from "./chart/Reducer";
import ErrorReducer from "./error/Reducer";


export default combineReducers({
    errorReducer: ErrorReducer,
    rportReducer: ReportReducer,
    environmentReducer: EnvironmentReducer,
    chartReducer: ChartReducer
})