import { isObjectEmpty } from "./objectUtilities";
import { genrateUDM } from "./udmGenerator";
import AppURL from "../../assets/url.json";

const downloadPDF = (Base64str) => {
    let byteChars = window.atob(Base64str);
    let dataArray = new Array(byteChars.length);
    for (let index = 0; index < byteChars.length; index++) {
        dataArray[index] = byteChars.charCodeAt(index);
    }
    let byteArray = new Uint8Array(dataArray);
    let file = new Blob([byteArray], { type: "application/pdf" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
}

const generatePdfUrl = async (endPoint, dataInJson) => {
    const protocol = window.location.protocol;
    const domainName = window.location.hostname;
    // const endPoint = `/apix/downloadPdf/`;
    const b64Data = encodeURI(btoa(JSON.stringify(genrateUDM(dataInJson))));
    let pdfUrl = "";
    if (!isObjectEmpty(dataInJson)) {
        const urlDomain = domainName === "localhost" ? AppURL[0]["en-CA"].replace(/\/$/, '') : `${protocol}//${domainName}`;
        const rawPdfUrl = `${urlDomain}${endPoint}${b64Data}`;
        pdfUrl = rawPdfUrl; /* await generateShortenUrl(rawPdfUrl); */
    } else {
        console.log(`empty obj ${dataInJson}`);
    }
    return pdfUrl
}

// const generateShortenUrl = async (url) => {
//     const response = await fetch(`https://api.shrtco.de/v2/shorten?url=${url}`);
//     const data = await response.json();
//     return await data.result.full_short_link;
// };

export {
    downloadPDF,
    generatePdfUrl
}