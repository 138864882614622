import { LANG, P, H2 } from "@manulife/mux";
const healthStyleModel = ({ lang }) => {
  return lang === LANG.EN_CA ? (
    <>
      <H2>Smoking status</H2>
      <P>
        Smoker rates will be charged if your client uses any tobacco product
        including cigarettes, e-cigarettes, cigar, pipe, chewing tobacco,
        nicotine gum or patches.
      </P>
      <P>
        Non-smoker rates will be charged if your client has not used any form of
        tobacco or nicotine products in the past 12 months.
      </P>
      <P>
        For Family Term and Family Term with <i>Vitality Plus</i>™, Healthstyle
        3 is used for a standard non-smoker risk and Healthstyle 5 is used if
        your client has used any tobacco/nicotine products in the past 12
        months.
      </P>
      <P>
        An occasional cigar smoker may be considered a non-smoker if all the
        following apply:
      </P>
      <ul>
        <li>They smoke 12 cigars or less per year.</li>
        <li>Smoking details are fully disclosed on the application.</li>
        <li>Urine tests show no cotinine.</li>
        <li>
          The person is not rated substandard for a smoking-related impairment.
        </li>
      </ul>
    </>
  ) : (
    <>
      <H2>Usage du tabac</H2>
      <P>
        Les taux fumeurs sont facturés lorsque le client consomme un produit du
        tabac comme la cigarette, la cigarette électronique, le cigare, la pipe,
        le tabac à chiquer ou la gomme à mâcher et les timbres à la nicotine.
      </P>
      <P>
        Votre client se verra accorder les taux non-fumeurs s’il n’a fait usage
        d’aucun type de tabac ou de produit à base de nicotine au cours des 12
        derniers mois.
      </P>
      <P>
        Pour les contrats Temporaire Famille et Temporaire Famille avec
        <i>Vitalité Plus</i>ᴹᶜ, le code de santé 3 est utilisé quand il s’agit
        d’un risque standard non-fumeur et le code de santé 5 quand le client a
        consommé un produit du tabac ou un produit contenant de la nicotine au
        cours des 12 derniers mois.
      </P>
      <P>
        Les fumeurs occasionnels de cigares peuvent être considérés comme
        non-fumeurs quand toutes les conditions suivantes s’appliquent :
      </P>
      <ul>
        <li>Ils fument 12 cigares ou moins par année.</li>
        <li>
          Les renseignements sur le tabagisme sont entièrement divulgués dans la
          demande.
        </li>
        <li>Les tests d’urine n’ont décelé aucune cotinine.</li>
        <li>
          La personne n’est pas considérée comme ayant un problème de santé lié
          au tabagisme.
        </li>
      </ul>
    </>
  );
};
export default healthStyleModel;
