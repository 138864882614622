import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { LANG, TextInput, useLang } from '@manulife/mux';
import { addCurrencySign, getAmountNumbers } from '../../../services/helpers/commonHelpers';
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";

function RtgAmount({ fieldName, fieldLabel, fieldAriaLabel, validations }) {
    const { num2 } = NumberConstants;
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods

    const handleAmountChange = (newValue, event, onChange) => {
        /* onChange */
        const amountInNumber = getAmountNumbers(newValue, selectedLang);
        onChange(addCurrencySign(amountInNumber, selectedLang));
        if (selectedLang === LANG.FR_CA) {
            setTimeout(() => {
                event.target.setSelectionRange(
                    event.target.value.length - num2,
                    event.target.value.length - num2
                );
            }, 0);
        };
    };

    return (
        <>
            <Controller
                control={control}
                name={fieldName}
                rules={validations}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                }) => (
                    <TextInput
                        label={fieldLabel}
                        ariaLabel={fieldAriaLabel}
                        ariaRequired={true}
                        data-testid={name}
                        placeholder={selectedLang === LANG.EN_CA ? "$0" : "0"}
                        customStyle={{
                            rootStyle: {
                                width: "100%",
                            },
                        }}
                        ref={ref}
                        value={value}
                        onBlur={onBlur}
                        onFocus={(newValue, event) => {
                            if (selectedLang === LANG.FR_CA) {
                                event.target.setSelectionRange(
                                    event.target.value.length - num2,
                                    event.target.value.length - num2
                                );
                            }
                        }}
                        onChange={(newValue, event) => handleAmountChange(newValue, event, onChange)}
                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                    />
                )}
            />
        </>
    );
};

export default RtgAmount;