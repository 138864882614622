import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "./RtgResult.css";
import { formatAmount, replaceKeysWithValues } from "../../services/helpers/commonHelpers";
import { BoldChartHeading, BoldChartText } from "../../assets/appStyledComponents/styledParagraphComponents";
import ResultLables from "../../assets/resultLabels.json";
import Labels from "../../assets/labels";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";

const { num3, num30, num15, num10, num4500, num1125, num5, num21, num24, num41, num44 } = NumberConstants;
const LineChart = ({ chartData, validatedData = {}, selectedLang }) => {
  const { gender = "", age = 0, amount = 0, healthStyle = "" } = validatedData;
  const annualPolicyPremium = chartData?.vector.filter(ele => ele.type === "AnnualPolicyPremium");
  const dataSets = (annualPolicyPremium, chartData) => {
    return [
      {
        label: "Data 1",
        data: annualPolicyPremium.find(ele => ele.coverageProduct === "Term10").value,
        fill: false,
        borderWidth: num3,
        borderColor: "rgba(0, 196, 109, 0.8)",
        backgroundColor: "rgba(75,192,192,0.4)",
        pointRadius: 0, // Set pointRadius to 0 to remove the dots
        pointHoverRadius: 0,
        stepped: chartData.type === "PremiumCompareRenewal" ? false : "before",
      },
      {
        label: "Data 2",
        data: annualPolicyPremium.find(ele => ele.coverageProduct === "Term20").value,
        fill: false,
        borderWidth: num3,
        borderColor: "#FE9400",
        backgroundColor: "rgba(255,99,132,0.4)",
        pointRadius: 0, // Set pointRadius to 0 to remove the dots
        pointHoverRadius: 0,
        stepped: "before",
      },
      {
        label: "Data 3",
        data: annualPolicyPremium.find(ele => ele.coverageProduct === "Term65").value,
        fill: false,
        borderWidth: num3,
        borderColor: "#0273B5",
        backgroundColor: "rgba(255,99,132,0.4)",
        pointRadius: 0, // Set pointRadius to 0 to remove the dots
        pointHoverRadius: 0,
        stepped: "before",
      },
    ];
  };
  const data = {
    labels: chartData?.vector.find(ele => ele.type === "Age").value,
    datasets: dataSets(annualPolicyPremium, chartData)
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: num30
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        border: {
          color: "black"
        },
        title: {
          display: true,
          text: ResultLables.xLabel[selectedLang],
          color: "#00C46D",
          font: {
            size: num15,
            weight: "bold"
          }
        },
        ticks: {
          callback: (value, index) => {
            return index % num10 === 0 ? chartData?.vector.find(ele => ele.type === "Age").value[index] : "";
          },
          color: "black",
          font: {
            size: num10,
            weight: "bold",
          }
        }
      },
      y: {
        beginAtZero: true,
        suggestedMax: num4500,
        maxTicksLimit: num10,
        grid: {
          drawTicks: false
        },
        border: {
          color: "black"
        },
        title: {
          display: true,
          text: ResultLables.yLabel[selectedLang],
          color: "#00C46D",
          font: {
            size: num15,
            weight: "bold"
          }
        },
        ticks: {
          callback: (value) => formatAmount(value, selectedLang),
          min: 0,
          max: num4500,
          stepSize: num1125,
          color: "black",
          font: {
            size: num10,
            weight: "bold",
          }
        },
      },
    },
  };

  const ageLimit = (Number(age) >= num21 && Number(age) <= num24) || (Number(age) >= num41 && Number(age) <= num44);

  return (
    <>
      <BoldChartHeading style={{ margin: 0, color: "#0273B5" /* 2E4F7B */ }}>{ResultLables.chartTitle[selectedLang]}</BoldChartHeading>
      <div className="chartContainer" >
        <Line data={data} options={options} />
      </div>
      <BoldChartText>{replaceKeysWithValues(
        ResultLables.chartSubTitle[selectedLang],
        {
          curr_sex: Labels.gender[`${selectedLang}`].options.find(ele => ele.value === gender).label,
          curr_age: age,
          curr_healthStyle: healthStyle === "HS3" ? num3 : num5,
          curr_amount: formatAmount(amount, selectedLang)
        }
      )}</BoldChartText>
      {
        (chartData.type === "PremiumCompareNewPolicy" && ageLimit) && <BoldChartText>{ResultLables.newPurchaseChartText[selectedLang]}</BoldChartText>
      }
    </>
  );
};

export default LineChart;

